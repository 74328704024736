let config = null;

const server = "production";

if (server === "development") {
  config = {
    api_url: process.env.REACT_APP_API_URL,
    // token: import.meta.env.VITE_REACT_APP_TOKEN,ba
  };
} else if (server === "production") {
  config = {
    api_url: process.env.REACT_APP_API_URL,
    // token: import.meta.env.VITE_REACT_APP_TOKEN,
  };
}

export default config;
