import React, { useState } from "react";
import userResources from "api/userResources";
import Checkbox from "components/checkbox";
import { FcGoogle } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { selectUserInfo, setUserInfo, login } from "../../../redux/userSlice";
import UserInputField from "components/UserInputfield/UserInputfield";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"; // Import eye icons

export default function LoginForm({ closeButton }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [showPassword, setShowPassword] = useState(false); // Password visibility toggle
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector(selectUserInfo);
  console.log(userInfo, "userinfo");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      email,
      password,
    };
    try {
      const response = await userResources.login(data);
      dispatch(login(true));
      dispatch(setUserInfo(response.data));
      console.log(response);

      if (response.data.data.role_id === 1) {
        navigate("/dashboard");
      } else {
        navigate("/");
        window.scrollTo(0, 0);
      }
      toast.success("Login successful!");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Login failed. Please try again.";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={submit}
      className="mt-[0vh] w-full max-w-full flex-col items-center"
    >
      <div className="flex items-start justify-between">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-[#bb8e25]">
          Sign In
        </h4>
        {closeButton && <div>{closeButton}</div>}
      </div>
      <p className="mb-9 ml-1 text-base text-gray-600">
        Enter your email and password to sign in!
      </p>

      <div className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary hover:cursor-pointer dark:bg-navy-800">
        <div className="rounded-full text-xl">
          <FcGoogle />
        </div>
        <h5 className="text-sm font-medium text-navy-700 dark:text-white">
          Sign In with Google
        </h5>
      </div>
      <div className="mb-6 flex items-center gap-3">
        <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
        <p className="text-base text-gray-600 dark:text-white"> or </p>
        <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
      </div>
      <UserInputField
        variant="auth"
        extra="mb-3"
        label="Email*"
        placeholder="mail@simmmple.com"
        id="email"
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        aria-label="Email"
      />
      <div className="relative mb-3 w-full">
        <UserInputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type={showPassword ? "text" : "password"} // Toggle password visibility
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          aria-label="Password"
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute right-3 top-11 text-xl text-gray-500"
          aria-label="Toggle password visibility"
        >
          {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
        </button>
      </div>
      <div className="mb-4 flex items-center justify-between px-2">
        <div className="flex items-center">
          <Checkbox />
          <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
            Keep me logged In
          </p>
        </div>
        <a
          className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          href="/"
        >
          Forgot Password?
        </a>
      </div>
      <button
        type="submit"
        disabled={loading}
        className={`linear mt-2 w-full rounded-xl ${
          loading ? "bg-gray-400" : "bg-[#bb8e25]"
        } py-[12px] text-base font-medium text-white transition duration-200`}
      >
        {loading ? "Signing In..." : "Sign In"}
      </button>
    </form>
  );
}
