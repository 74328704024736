import Background from "components/Home/Background/Background";
import HeroSearchBox from "components/Home/HeroSearchBox/HeroSearchBox";
import React from "react";
import { hero_background } from "utils/images";

export default function Home() {
  return (
    <section className="m-auto">
      <Background image={hero_background} />
      <section className="relative -top-40">
        <HeroSearchBox />
      </section>
    </section>
  );
}
