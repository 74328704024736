import React from "react";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import katariatravels from "utils/images";
import LoginForm from "components/Home/LoginForm/LoginForm";

export default function AgencyLogin() {
  return (
    <section>
      <div>
        <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
          <FixedPlugin />
          <main className={`mx-auto`}>
            <div className="relative flex">
              <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:min-h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
                <div className="mb-auto flex flex-col pl-5 pr-5 md:pl-12 md:pr-0 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                  <div className="mt-0 flex h-full w-full items-center justify-center px-2 sm:mt-[10vh] md:pl-4 lg:pl-0 xl:max-w-[420px]">
                    <LoginForm />
                  </div>
                  <div
                    className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[50vw]"
                    style={{
                      backgroundImage: `url(${katariatravels})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </section>
  );
}
