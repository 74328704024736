import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import React from "react";
import MutaualForm from "./MutaulForm";
import { useLocation } from "react-router-dom";

export default function Oneway() {
  const location = useLocation();

  return (
    <section className="">
      {/* <ScrollToTopOnRouteChange /> */}
      <MutaualForm
        thisoneWay={true}
        packageType={"one_way"}
        updateData={location.state}
      />
    </section>
  );
}
