import React, { useEffect, useState } from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import { MaterialReactTable } from "material-react-table";
import { Edit, Delete } from "@mui/icons-material"; // Importing Material UI icons
import { FaMailBulk, FaWhatsapp } from "react-icons/fa";
import SubmitButton from "components/SubmitButton/SubmitButton";
import userResources from "api/userResources";
import { AiOutlineClose, AiOutlineInfoCircle } from "react-icons/ai";
import UserInfoModal from "components/UserInfoModal/UserInfoModal";
import { avtar } from "utils/images";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import DateFilter from "components/DateFilter/DateFilter";
import SelectFilter from "components/SelectFilter/SelectFilter";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../../redux/userSlice";
export default function EnquiryList() {
  const userInfo = useSelector(selectUserInfo);

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [customer_name, setCustomerName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [bookingReference, setBookingReference] = useState("");
  const [enquiry_payment_status_id, setEnquiryPaymentStatusId] = useState(null);
  const [enquiryPaymentStausData, setEnquiryPaymentStatusData] = useState([]);
  const [enquiry_source_id, setEnquirySourceId] = useState(null);
  const [enquiryData, setEnquiryData] = useState([]);
  const [enquiryStausData, setEnquiryStatusData] = useState([]);
  const [enquiry_status_id, setEnquiryStatusId] = useState(null);
  const [AssignToData, setAssignToData] = useState([]);
  const [AssignToUserId, setAssignToUserId] = useState(null);
  const [createdByData, setCreatedByData] = useState([]);
  const [createdById, setCreatedById] = useState(null);
  const [air_line_id, setAirLineId] = useState(null);
  const [airlineData, setAirlineData] = useState([]);
  const [columnsFilterValue, setColumnsFilterValue] = useState([]);
  const [departureDateFilterValue, setDepartureDateFilterValue] = useState("");
  const [returnDateFilterDate, setReturnDateFilterDate] = useState("");
  const [createdAtFilterDate, setCreatedAtFilterDate] = useState("");
  const [updatedFilterDate, setUpdatedFilterDate] = useState("");
  const [budgetRange, setBudgetRange] = useState({ min: null, max: null });
  console.log(enquiry_source_id, "enquiry_source_id");

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = (assignedUser) => {
    setUserProfile(assignedUser);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);

    setUserProfile({}); // Reset user profile on close
  };

  const getEnquiryList = async (pageIndex, pageSize, globalFilter) => {
    setIsLoading(true); // Set loading state to true when fetching starts
    setIsError(false); // Reset error state before fetching

    try {
      const params = {
        page: pageIndex + 1 || 1, // Default to page 1 if pageIndex is not provided
        limit: pageSize || 10,
        email: globalFilter || emailFilter || "",
        title: title || null,
        type: type || null,
        customer_name: customer_name || null,
        phone_number: phone_number || null,
        air_line_id: air_line_id || null,
        booking_reference: bookingReference || null,
        departure_date: departureDateFilterValue || null,
        return_date: returnDateFilterDate || null,
        enquiry_payment_status_id: enquiry_payment_status_id || null,
        enquiry_source_id: enquiry_source_id || null,
        enquiry_status_id: enquiry_status_id || null,
        created_by_user_id: createdById || null,
        assigned_to_user_id: AssignToUserId || null,
        max_price: budgetRange.max || null || null, // Set max_price if budgetRange.max exists
        min_price: budgetRange.min || null || null, // Set min_price if budgetRange.min exists
      };

      const response = await userResources.enquiryList(params);
      setTotalRows(response.data.paginate_data.total);
      setData(response.data.data);
    } catch (error) {
      console.error(error); // Log the error
      setIsError(true); // Set error state to true on error
      if (isError) {
        setData([]);
      }
    } finally {
      setIsLoading(false); // Set loading state to false when fetching ends
    }
  };
  const formatData = (data, key) =>
    data.map((item) => ({
      id: item?.id,
      name: item?.[key],
    }));

  const handleApiResult = (result, setter, key, label) => {
    if (result.status === "fulfilled") {
      setter(formatData(result.value.data.data, key));
    } else {
      console.error(`Error fetching ${label} data:`, result.reason);
    }
  };

  const getEnquirySource = async () => {
    try {
      const results = await Promise.allSettled([
        userResources.getSources(),
        userResources.airlines(),
        userResources.enquityStatus(),
        userResources.paymentStatus(),
        userResources.userList({
          role_id: Number(process.env.REACT_APP_ROLE_EMPLOYEE),
        }),
        userResources.userList({ role_id: "", size: 9999 }),
      ]);

      const [
        enquirySourceResult,
        airlineResult,
        enquiryStatusResult,
        paymentStatusResult,
        userListResult,
        allUserResult,
      ] = results;

      // Map results to their respective states
      const apiResults = [
        {
          result: enquirySourceResult,
          setter: setEnquiryData,
          key: "name",
          label: "Enquiry Source",
        },
        {
          result: airlineResult,
          setter: setAirlineData,
          key: "airline_name",
          label: "Airline",
        },
        {
          result: enquiryStatusResult,
          setter: setEnquiryStatusData,
          key: "name",
          label: "Enquiry Status",
        },
        {
          result: paymentStatusResult,
          setter: setEnquiryPaymentStatusData,
          key: "name",
          label: "Payment Status",
        },
        {
          result: userListResult,
          setter: setAssignToData,
          key: "name",
          label: "User List",
        },
        {
          result: allUserResult,
          setter: setCreatedByData,
          key: "name",
          label: "All User List",
        },
      ];

      // Handle each API result
      apiResults.forEach(({ result, setter, key, label }) => {
        handleApiResult(result, setter, key, label);
      });
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  // Column definitions for the table
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnFilter: false, // Enable filter for this column
      },
      {
        accessorKey: "title",
        header: "Title",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "customer_name",
        header: "Customer Name",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "email",
        header: "Email Address",
        enableClickToCopy: true,
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "phone_number",
        header: "Phone Number",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },

      {
        accessorKey: "enquirySource",
        header: "Enquiry Source",
        Cell: ({ cell }) => cell.getValue()?.name || "N/A",
        filterVariant: "select",
        Filter: ({ column }) => (
          <SelectFilter
            options={enquiryData}
            selectedId={enquiry_source_id}
            setSelectedId={setEnquirySourceId}
            column={column}
            placeholder="Select Enquiry Source"
          />
        ),
      },
      {
        accessorKey: "type",
        header: "Type",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "invoice_number",
        header: "Invoice",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "from",
        header: "From",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
        filterVariant: "select",
      },
      {
        accessorKey: "to",
        header: "To",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
        filterVariant: "select",
      },
      {
        accessorKey: "departure_date",
        header: "Departure Date",
        Filter: ({ column }) => (
          <DateFilter
            column={column}
            selectedDate={departureDateFilterValue}
            setSelectedDate={setDepartureDateFilterValue}
          />
        ),
        Cell: ({ cell }) => cell.getValue() || "N/A",
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "return_date",
        header: "Return Date",
        Cell: ({ cell }) => cell.getValue() || "N/A",
        Filter: ({ column }) => (
          <DateFilter
            column={column}
            selectedDate={returnDateFilterDate}
            setSelectedDate={setReturnDateFilterDate}
          />
        ),
      },
      {
        header: "Traveler Details",
        enableColumnFilter: false, // Enable filter for this column

        Cell: ({ row }) => {
          const adult = row.original.adult ?? "N/A";
          const child = row.original.child ?? "N/A";
          const infant = row.original.infant ?? "N/A";
          const classOfTravel = row.original.class_of_travel ?? "N/A";

          return (
            <div className="whitespace-nowrap">
              <p>Adults: {adult}</p>
              <p>Children: {child}</p>
              <p>Infants: {infant}</p>
              <p>Class: {classOfTravel}</p>
            </div>
          );
        },
      },
      {
        accessorKey: "airLine",
        header: "Preferred Airline",
        enableColumnFilter: true,
        Cell: ({ cell }) => {
          const airline = cell.getValue();
          return airline?.airline_name || "N/A";
        },
        filterVariant: "select",
        Filter: ({ column }) => (
          <SelectFilter
            options={airlineData}
            selectedId={air_line_id}
            setSelectedId={setAirLineId}
            column={column}
            placeholder="Select Airline"
          />
        ),
      },
      {
        accessorKey: "booking_reference",
        header: "Booking Reference",
        enableColumnFilter: true, // Enable filter for this column
      },
      {
        accessorKey: "budget",
        header: "Budget",
        Cell: ({ cell }) => {
          // Function to generate a random color
          const getRandomColor = () => {
            const letters = "0123456789ABCDEF";
            let color = "#";
            for (let i = 0; i < 6; i++) {
              color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
          };
          // Function to determine if a color is light or dark
          const isColorLight = (color) => {
            // Convert hex to RGB
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            // Calculate the brightness (perceived brightness)
            const brightness = (r * 299 + g * 587 + b * 114) / 1000;
            return brightness > 155; // Adjust threshold for light vs. dark
          };

          const randomColor = getRandomColor();
          const textColor = isColorLight(randomColor) ? "#000" : "#fff"; // Black for light, white for dark

          return (
            <div
              style={{
                backgroundColor: randomColor, // Set random color
                borderRadius: "0.25rem",
                color: textColor, // Set dynamic text color
                maxWidth: "15ch",
                padding: "0.25rem",
              }}
            >
              {cell.getValue()?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        },
        filterFn: (row, columnId, filterValue) => {
          const budget = row.getValue(columnId);
          const { min, max } = filterValue || {};
          return (
            (min === "" || budget >= parseFloat(min)) &&
            (max === "" || budget <= parseFloat(max))
          );
        },
        // Add custom filter UI
        Filter: () => (
          <div className="grid grid-cols-2 items-center gap-2">
            <div className="relative">
              <input
                type="number"
                placeholder="Min"
                value={budgetRange.min}
                onChange={(e) => {
                  const value = e.target.value;
                  setBudgetRange((prev) => ({ ...prev, min: value }));
                }}
                style={{ padding: "5px" }}
                className="border-b bg-transparent text-gray-900 outline-none"
              />
              {budgetRange.min && (
                <button
                  onClick={() =>
                    setBudgetRange((prev) => ({ ...prev, min: "" }))
                  }
                  className="absolute right-0 top-1/2 -translate-y-1/2 transform"
                  aria-label="Clear Min Budget"
                >
                  <AiOutlineClose size={16} />
                </button>
              )}
            </div>
            <div className="relative">
              <input
                type="number"
                placeholder="Max"
                value={budgetRange.max}
                onChange={(e) => {
                  const value = e.target.value;
                  setBudgetRange((prev) => ({ ...prev, max: value }));
                }}
                style={{ padding: "5px" }}
                className="border-b bg-transparent text-gray-900 outline-none"
              />
              {budgetRange.max && (
                <button
                  onClick={() =>
                    setBudgetRange((prev) => ({ ...prev, max: "" }))
                  }
                  className="absolute right-0 top-1/2 -translate-y-1/2 transform"
                  aria-label="Clear Max Budget"
                >
                  <AiOutlineClose size={16} />
                </button>
              )}
            </div>
          </div>
        ),
      },
      {
        accessorKey: "paid_amount", // This is the new column for budget
        header: "Paid Amount",
        enableColumnFilter: false, // Enable filter for this column

        Cell: ({ cell }) => {
          // Function to generate a random color
          const getRandomColor = () => {
            const letters = "0123456789ABCDEF";
            let color = "#";
            for (let i = 0; i < 6; i++) {
              color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
          };
          // Function to determine if a color is light or dark
          const isColorLight = (color) => {
            // Convert hex to RGB
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            // Calculate the brightness (perceived brightness)
            const brightness = (r * 299 + g * 587 + b * 114) / 1000;
            return brightness > 155; // Adjust threshold for light vs. dark
          };

          const randomColor = getRandomColor();
          const textColor = isColorLight(randomColor) ? "#000" : "#fff"; // Black for light, white for dark

          return (
            <div
              style={{
                backgroundColor: randomColor, // Set random color
                borderRadius: "0.25rem",
                color: textColor, // Set dynamic text color
                maxWidth: "15ch",
                padding: "0.25rem",
              }}
            >
              {cell.getValue()?.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </div>
          );
        },
      },

      {
        accessorKey: "enquiryStatus", // Adjust this to match your actual data structure
        header: "Enquiry Status",
        filterVariant: "select",
        enableColumnFilter: true, // Enable filter for this column
        Filter: ({ column }) => (
          <SelectFilter
            options={enquiryStausData}
            selectedId={enquiry_status_id}
            setSelectedId={setEnquiryStatusId}
            column={column}
            placeholder="Select enquiry status"
          />
        ),
        Cell: ({ cell }) => {
          const enquiryStatus = cell.getValue();
          const statusName = enquiryStatus?.name || "N/A"; // Use the name property for display
          // Function to determine text color based on status name
          const getEnquiryStatusTextColor = (status) => {
            switch (status) {
              case "New":
                return "text-blue-600"; // Blue for New
              case "Reviewing Options":
                return "text-yellow-600"; // Yellow for Reviewing Options
              case "Awaiting Customer Confirmation":
                return "text-orange-600"; // Orange for Awaiting Customer Confirmation
              case "Booked":
                return "text-green-600"; // Green for Booked
              case "Closed":
                return "text-gray-600"; // Gray for Closed
              default:
                return "text-red-600"; // Default red for unknown status
            }
          };

          return (
            <div className="relative inline-flex items-center gap-1">
              <span className="group relative ml-2 cursor-pointer">
                {/* Info Icon */}
                <AiOutlineInfoCircle className="text-gray-600 hover:text-gray-800" />
                {/* Tooltip */}
                <span className="absolute -top-8 left-0 hidden w-48 bg-gray-700 p-2 text-xs text-white group-hover:block">
                  <strong>Status Info:</strong>
                  <br />
                  ID: {enquiryStatus?.id || "N/A"}
                  <br />
                  Status: {statusName}
                  <br />
                  Slug: {enquiryStatus?.slug || "N/A"}
                </span>
              </span>
              <span className={getEnquiryStatusTextColor(statusName)}>
                {statusName}
              </span>
            </div>
          );
        },
      },
      {
        accessorKey: "enquiryPaymentStatus", // Adjust this to match your actual data structure
        header: "Payment Status",
        filterVariant: "select",
        enableColumnFilter: true, // Enable filter for this column
        Filter: ({ column }) => (
          <SelectFilter
            options={enquiryPaymentStausData}
            selectedId={enquiry_payment_status_id}
            setSelectedId={setEnquiryPaymentStatusId}
            column={column}
            placeholder="Select enquiry payment status"
          />
        ),
        Cell: ({ cell }) => {
          const paymentStatus = cell.getValue();
          const statusName = paymentStatus?.name || "N/A"; // Use the name property for display

          // Function to determine text color based on payment status
          const getPaymentStatusTextColor = (status) => {
            switch (status) {
              case "Paid":
                return "text-green-700"; // Green for Paid
              case "Pending":
                return "text-orange-500"; // Orange for Pending
              case "Not Paid":
                return "text-red-600"; // Red for Not Paid
              default:
                return "text-gray-600"; // Default gray for unknown status
            }
          };

          return (
            <div className="relative inline-flex items-center gap-1">
              <span className="group relative ml-2 cursor-pointer">
                {/* Info Icon */}
                <AiOutlineInfoCircle className="text-gray-600 hover:text-gray-800" />
                {/* Tooltip */}
                <span className="absolute -top-8 left-0 hidden w-48 bg-gray-700 p-2 text-xs text-white group-hover:block">
                  <strong>Payment Status Info:</strong>
                  <br />
                  ID: {paymentStatus?.id || "N/A"}
                  <br />
                  Status: {statusName}
                  <br />
                  Slug: {paymentStatus?.slug || "N/A"}
                </span>
              </span>
              <span className={getPaymentStatusTextColor(statusName)}>
                {statusName}
              </span>
            </div>
          );
        },
      },
      {
        accessorKey: "assignedToUser",
        header: "Assigned To",
        filterVariant: "select",
        enableColumnFilter: true, // Enable filter for this column
        Filter: ({ column }) => (
          <SelectFilter
            options={AssignToData}
            selectedId={AssignToUserId}
            setSelectedId={setAssignToUserId}
            column={column}
            placeholder="Assign to..."
          />
        ),
        Cell: ({ cell }) => {
          const assignedUser = cell.getValue();

          return (
            <div className="flex items-center gap-2">
              <div className="tooltip" data-tip="Profile">
                <img
                  onClick={() => handleOpenModal(assignedUser)} // Wrap in an arrow function
                  className="h-10 w-10 cursor-pointer rounded-full"
                  src={assignedUser?.full_path}
                  alt={assignedUser?.name}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = avtar; // Default avatar
                  }}
                />
              </div>
              <div>
                <p>{assignedUser?.name}</p>
                <p>{assignedUser?.phone_no}</p>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "remark",
        header: "Remarks",
        enableColumnFilter: false, // Enable filter for this column
      },
      {
        accessorKey: "created_at", // Adjust this to match your actual data structure
        header: "Created Date",
        filterVariant: "select",
        enableColumnFilter: true, // Enable filter for this column
        Filter: ({ column }) => (
          <DateFilter
            column={column}
            selectedDate={createdAtFilterDate}
            setSelectedDate={setCreatedAtFilterDate}
          />
        ),

        Cell: ({ cell }) => {
          const createdAt = new Date(cell.getValue());
          const date = createdAt.toLocaleDateString(); // Get the date portion
          const time = createdAt.toLocaleTimeString(); // Get the time portion

          return (
            <div className="flex flex-col">
              <p
                className="font-semibold
              "
              >
                Date: <span className="font-light">{date}</span>
              </p>{" "}
              {/* Display the date */}
              <p
                className="font-semibold
              "
              >
                Time: <span className="font-light">{time}</span>
              </p>{" "}
              {/* Display the time */}
            </div>
          );
        },
      },
      {
        accessorKey: "updated_at", // Adjust this to match your actual data structure
        header: "Updated Date",
        enableColumnFilter: true, // Enable filter for this column
        Filter: ({ column }) => (
          <DateFilter
            column={column}
            selectedDate={updatedFilterDate}
            setSelectedDate={setUpdatedFilterDate}
          />
        ),
        Cell: ({ cell }) => {
          const updatedAt = new Date(cell.getValue());
          const date = updatedAt.toLocaleDateString(); // Get the date portion
          const time = updatedAt.toLocaleTimeString(); // Get the time portion

          return (
            <div className="flex flex-col">
              <p
                className="font-semibold
              "
              >
                Date: <span className="font-light">{date}</span>
              </p>{" "}
              {/* Display the date */}
              <p
                className="font-semibold
              "
              >
                Time: <span className="font-light">{time}</span>
              </p>{" "}
              {/* Display the time */}
            </div>
          );
        },
      },
      {
        accessorKey: "createdByUser",
        header: "Created By",
        enableColumnFilter: true, // Enable filter for this column
        Filter: ({ column }) => (
          <SelectFilter
            options={createdByData}
            selectedId={createdById}
            setSelectedId={setCreatedById}
            column={column}
            placeholder="created By..."
          />
        ),
        Cell: ({ cell }) => {
          const assignedBy = cell.getValue();

          return (
            <div className="flex items-center gap-2">
              <div className="tooltip" data-tip="Profile">
                <img
                  onClick={() => handleOpenModal(assignedBy)} // Wrap in an arrow function
                  className="h-10 w-10 cursor-pointer rounded-full"
                  src={assignedBy?.full_path}
                  alt={assignedBy?.name}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = avtar; // Default avatar
                  }}
                />
              </div>
              <div>
                <p>{assignedBy?.name}</p>
                <p>{assignedBy?.phone_no}</p>
              </div>
            </div>
          );
        },
      },
      {
        accessorKey: "action",
        header: "Action",
        enableColumnFilter: false, // Enable filter for this column
        Cell: ({ row }) => (
          <div className="flex space-x-2">
            <Edit
              className="cursor-pointer text-blue-500"
              onClick={() => {
                const { package_type } = row.original; // Destructure for cleaner access
                if (package_type === "multi_city") {
                  navigate(`/admin/create-enquiry/flight/multi-city`, {
                    state: row.original,
                  });
                } else if (package_type === "return_way") {
                  navigate(`/admin/create-enquiry/flight/round-trip`, {
                    state: row.original,
                  });
                } else if (package_type === "one_way") {
                  navigate(`/admin/create-enquiry/flight/oneway`, {
                    state: row.original,
                  });
                }
              }}
            />
            {userInfo?.data?.role_id ===
              Number(process.env.REACT_APP_ROLE_SUPERADMIN) ||
            userInfo?.data?.role_id ===
              Number(process.env.REACT_APP_ROLE_ADMIN) ? (
              <ConfirmModal
                onConfirm={handleDelete}
                title="Delete Enquiry"
                message="Are you sure you want to delete this enquiry?"
                employeeId={row.original.id}
              />
            ) : null}
          </div>
        ),
      },
      {
        accessorKey: "history",
        header: "History",
        enableColumnFilter: false, // Enable filter for this column
        Cell: ({ row }) => (
          <div
            className="flex space-x-2"
            onClick={() =>
              navigate("/admin/enquiry-history", {
                state: row?.original?.children,
              })
            }
          >
            <SubmitButton add="History" />
          </div>
        ),
      },
    ],
    [
      enquiryData,
      enquiry_source_id,
      departureDateFilterValue,
      returnDateFilterDate,
      returnDateFilterDate,
      air_line_id,
      enquiry_status_id,
      enquiry_payment_status_id,
      AssignToUserId,
      createdAtFilterDate,
      createdById,
      budgetRange,
    ]
  );

  // Placeholder functions for handling actions
  const handleEdit = (enquiry) => {
    console.log("Edit Enquiry:", enquiry);
  };

  const handleDelete = (enquiry) => {
    console.log("Delete Enquiry:", enquiry);
  };

  const sendEmailToAll = () => {
    const emails = data.map((item) => item.email).join(",");
    const subject = "Hello Everyone!";
    const body = "Hi Team,\n\n";
    const mailtoLink = `mailto:${emails}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
  };

  // const sendWhatsAppToAll = () => {
  //   const message = "Hello team, this is a test message.";
  //   const phoneNumbers = data.map(
  //     (employee) => employee.phone_no.replace(/\D/g, "") // Ensure this is the correct key for phone numbers
  //   );
  //   const whatsappLinks = phoneNumbers.map(
  //     (phoneNumber) =>
  //       `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`
  //   );

  //   whatsappLinks.forEach((link) => {
  //     window.open(link);
  //   });
  // };

  // pagination
  const handleGlobalFilterChange = (value) => {
    setGlobalFilter(value); // Update the global filter state with the new value
  };
  const handlePaginationChange = (updatedPagination) => {
    setPagination(updatedPagination);
  };

  // console.log(columnsFilterValue, "columnsFilterValue");

  const handleFilterValues = () => {
    let titleFound = false;
    let nameFound = false;
    let phoneFound = false;
    let emailFound = false;
    let booking_reference_Found = false;
    let typeFound = false;

    columnsFilterValue.forEach((key) => {
      if (key.id === "customer_name") {
        setCustomerName(key.value);
        nameFound = true;
      } else if (key.id === "phone_number") {
        setPhoneNumber(key.value);
        phoneFound = true;
      } else if (key.id === "email") {
        setEmailFilter(key.value);
        emailFound = true;
      } else if (key.id === "title") {
        setTitle(key.value);
        titleFound = true;
      } else if (key.id === "booking_reference") {
        setBookingReference(key.value);
        booking_reference_Found = true;
      } else if (key.id === "type") {
        setType(key.value);
        typeFound = true;
      }
    });

    // If keys were not found, set values to null
    if (!nameFound) {
      setCustomerName(null);
    }
    if (!phoneFound) {
      setPhoneNumber(null);
    }
    if (!emailFound) {
      setEmailFilter(null);
    }
    if (!titleFound) {
      setTitle(null);
    }
    if (!booking_reference_Found) {
      setBookingReference(null);
    }
    if (!typeFound) {
      setType(null);
    }
  };

  useEffect(() => {
    handleFilterValues();
  }, [columnsFilterValue]);

  useEffect(() => {
    getEnquiryList(pagination.pageIndex, pagination.pageSize, globalFilter);
  }, [
    pagination,
    globalFilter,
    customer_name,
    title,
    departureDateFilterValue,
    returnDateFilterDate,
    emailFilter,
    phone_number,
    air_line_id,
    enquiry_payment_status_id,
    enquiry_source_id,
    AssignToUserId,
    bookingReference,
    enquiry_status_id,
    type,
    isError,
    createdById,
  ]);

  useEffect(() => {
    const delay = setTimeout(() => {
      getEnquiryList(pagination.pageIndex, pagination.pageSize, globalFilter);
    }, 1000); // 1 second delay

    return () => clearTimeout(delay); // Clear timeout if min or max changes within 1 second
  }, [budgetRange.max, budgetRange.min]);

  useEffect(() => {
    getEnquirySource();
    getEnquiryList();
  }, []);
  return (
    <section className="mt-5">
      <ScrollToTopOnRouteChange />
      <div className="mb-4 flex gap-4">
        {/* <button
          onClick={sendEmailToAll}
          className="rounded bg-blue-600 px-4 py-2 text-white hover:bg-blue-700"
        >
          Send Email to All
        </button> */}
        <SubmitButton onClick={sendEmailToAll} add="Send Email to All" />
        {/* <button
          onClick={sendWhatsAppToAll}
          className="rounded bg-green-600 px-4 py-2 text-white hover:bg-green-700"
        >
          Send WhatsApp to All
        </button> */}
      </div>

      {/* Modal for User Info */}
      <div className="">
        {isModalOpen && (
          <UserInfoModal user={userProfile} onClose={handleCloseModal} />
        )}
      </div>

      <MaterialReactTable
        columns={columns}
        data={data}
        state={{
          pagination,
          isLoading,
          showProgressBars: isLoading,
          showAlertBanner: isError,
        }} // Use both states here
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        enableColumnFilters={true} // Pass the current visibility state
        initialState={{ showColumnFilters: true }}
        onColumnFiltersChange={setColumnsFilterValue}
        enableSorting={false}
        enableColumnOrdering={false}
        enableRowActions
        enablePagination
        onPaginationChange={handlePaginationChange}
        manualPagination
        rowCount={totalRows}
        pageCount={Math.ceil(totalRows / pagination.pageSize)}
        isLoading={isLoading}
        muiTablePaginationProps={{
          showFirstButton: true,
          showLastButton: true,
        }}
        enableGlobalFilter // Keep this true to allow global filtering
        onGlobalFilterChange={handleGlobalFilterChange} // Update the state on filter change
        globalFilter={globalFilter} // Pass the state variable to the table
        // editingMode="modal" // Ensure this is correctly set
        // enableEditing // This should also be true
        renderRowActions={({ row }) => (
          <div className="flex flex-nowrap gap-4">
            <button
              className="text-xl text-primary"
              onClick={() =>
                window.open(
                  `mailto:${row.original.email}?subject=Hello ${row.original.customer_name}!`
                )
              }
            >
              <FaMailBulk />
            </button>
            <button
              className="text-xl text-success"
              onClick={() =>
                window.open(
                  `https://wa.me/${row.original.phone_number.replace(
                    /\D/g,
                    ""
                  )}?text=Hello ${row.original.customer_name}!`
                )
              }
            >
              <FaWhatsapp />
            </button>
          </div>
        )}
        renderDetailPanel={({ row }) => (
          <div style={{ padding: "6px" }} className="max-w-7xl">
            {row.original.packages && row.original.packages.length > 0 ? (
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                      Enquiry ID
                    </th>
                    <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                      From
                    </th>
                    <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                      To
                    </th>
                    <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                      Departure Date
                    </th>
                    <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                      Created At
                    </th>
                    <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                      Package Type
                    </th>
                    <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                      Updated At
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {row.original.packages.map((pkg) => (
                    <tr key={pkg.enquiry_id}>
                      {" "}
                      {/* Use a unique identifier for the key */}
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {pkg.enquiry_id}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {pkg.from}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {pkg.to}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {pkg.departure_date}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {new Date(pkg.created_at).toLocaleString()}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {pkg.package_type}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {new Date(pkg.updated_at).toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No packages available.</p> // Optional message when no packages are present
            )}
          </div>
        )}
      />
    </section>
  );
}
