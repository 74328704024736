import axiosInstance from "./base";

const userResources = {
  // login: async (loginData) => {
  //   try {
  //     const response = await axiosInstance.post("/login", loginData);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  userVerify: (id) => {
    return axiosInstance.get(`/user/verify/${id}`);
  },
  login: (data) => {
    return axiosInstance.post("/login", data);
  },
  register: (data) => {
    return axiosInstance.post("/register", data);
  },
  employeeRegister: (data) => {
    return axiosInstance.post("/user/employee-register", data);
  },
  logout: () => {
    return axiosInstance.get("/user/logout");
  },
  role: () => {
    return axiosInstance.get("/roles/list");
  },
  createEnquiry: (data) => {
    return axiosInstance.post("/enquiries/save-and-update", data);
  },
  createEnquiryMultiWay: (data) => {
    return axiosInstance.post("/enquiries/save-and-update-multiple", data);
  },
  enquiryList: (params) => {
    const url = "/enquiries/list";

    // Use Axios's params option to handle query parameters automatically
    return axiosInstance.get(url, { params });
  },
  userList: ({ role_id, page = 0, size = 10, globalFilter }) => {
    let url = "/user/user-list";
    const params = [];

    // Add role_id if provided
    if (role_id) {
      params.push(`role_id=${role_id.value || role_id}`);
    }

    // Add pagination defaults if not provided
    params.push(`page=${page + 1}`); // Assuming 1-based page index for API
    params.push(`limit=${size}`);

    // Add global filter if provided
    if (globalFilter) {
      params.push(`email=${globalFilter}`);
    }

    // Append all params to the URL
    if (params.length) {
      url += `?${params.join("&")}`;
    }
    return axiosInstance.get(url);
  },
  userDelete: (id) => {
    return axiosInstance.delete(`/user/delete/${id}`);
  },
  addAirlines: (data) => {
    return axiosInstance.post("/air-lines/save-and-update", data);
  },
  // Assuming userResources is already defined and axiosInstance is configured
  airlines: (page = 0, size = 9999, globalFilter) => {
    let url = "/air-lines/list";
    const params = [];

    // Since page and size have default values, they’ll be used if not provided
    params.push(`page=${page + 1}`);
    params.push(`limit=${size}`);

    if (globalFilter) {
      // Check for a non-empty string here
      params.push(`airline_name=${globalFilter}`);
    }

    // If there are any parameters, add them to the URL
    if (params.length) {
      url += `?${params.join("&")}`;
    }

    return axiosInstance.get(url);
  },

  airlinesDelete: (id) => {
    return axiosInstance.delete(`/air-lines/delete/${id}`);
  },
  departments: (page = 0, size = 9999, globalFilter) => {
    let url = "/departments/list";

    // Add query parameters only if they are present
    const params = [];
    if (page !== undefined) {
      params.push(`page=${page + 1}`);
    }
    if (size !== undefined) {
      params.push(`limit=${size}`);
    }

    if (globalFilter !== undefined) {
      params.push(`departments=${globalFilter}`);
    }

    // If there are any parameters, add them to the URL
    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    return axiosInstance.get(url);
  },
  addDepartments: (data) => {
    return axiosInstance.post("/departments/save-and-update", data);
  },
  departmentDelete: (id) => {
    return axiosInstance.delete(`/departments/delete/${id}`);
  },

  paymentStatus: (page = 0, size = 9999, globalFilter) => {
    let url = "/enquiries-payment-status/list";

    // Add query parameters only if they are present
    const params = [];
    if (page !== undefined) {
      params.push(`page=${page + 1}`);
    }
    if (size !== undefined) {
      params.push(`limit=${size}`);
    }
    if (globalFilter !== undefined) {
      params.push(`enquiries-source=${globalFilter}`);
    }

    // If there are any parameters, add them to the URL
    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    return axiosInstance.get(url);
  },
  createStatus: (data) => {
    return axiosInstance.post("/enquiries-status/save-and-update", data);
  },
  enquityStatus: (page = 0, size = 9999, globalFilter) => {
    let url = "/enquiries-status/list";

    // Add query parameters only if they are present
    const params = [];
    if (page !== undefined) {
      params.push(`page=${page + 1}`);
    }
    if (size !== undefined) {
      params.push(`limit=${size}`);
    }
    if (globalFilter !== undefined) {
      params.push(`enquiries-source=${globalFilter}`);
    }

    // If there are any parameters, add them to the URL
    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    return axiosInstance.get(url);
  },
  enquiryStatusDelete: (id) => {
    return axiosInstance.delete(`/enquiries-status/delete/${id}`);
  },
  createSource: (data) => {
    return axiosInstance.post("/enquiries-source/save-and-update", data);
  },
  sourceDelete: (id) => {
    return axiosInstance.delete(`/enquiries-source/delete/${id}`);
  },
  getSources: (page = 0, size = 9999, globalFilter) => {
    let url = "/enquiries-source/list";

    // Add query parameters only if they are present
    const params = [];
    if (page !== undefined) {
      params.push(`page=${page + 1}`);
    }
    if (size !== undefined) {
      params.push(`limit=${size}`);
    }
    if (globalFilter !== undefined) {
      params.push(`enquiries-source=${globalFilter}`);
    }

    // If there are any parameters, add them to the URL
    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    return axiosInstance.get(url);
  },
  addHotel: (data) => {
    return axiosInstance.post("/hotel-enquire/save-and-update", data);
  },
};

export default userResources;
