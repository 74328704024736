import { useEffect, useState } from "react";
import {
  LoginCrasoualImage1,
  LoginCrasoualImage2,
  LoginCrasoualImage3,
  LoginCrasoualImage4,
} from "utils/images";
import LoginForm from "components/Home/LoginForm/LoginForm";
import Crossicon from "components/CrossIcon/Crossicon";

export default function Login({ openLogin, setOpenLogin }) {
  const [openSignUp, setOpenSignUp] = useState(false); // Manage SignUp modal state
  const slides = [
    {
      image: LoginCrasoualImage1,
      description:
        "Explore the beauty of Canada with Kataria Tours & Travel, offering customized itineraries for unforgettable experiences.",
    },
    {
      image: LoginCrasoualImage2,
      description:
        "Travel to the USA with confidence! Kataria Tours & Travel provides comprehensive travel assistance and support for seamless journeys.",
    },
    {
      image: LoginCrasoualImage3,
      description:
        "Discover the vibrant culture of Singapore with our exclusive tour packages that cater to all your travel needs.",
    },
    {
      image: LoginCrasoualImage4,
      description:
        "Experience the wonders of China with guided tours and personalized travel plans designed to create memories that last a lifetime.",
    },
  ];

  const [activeSlide, setActiveSlide] = useState(0);

  // Automatically change slides every 4 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {openLogin && (
        <dialog
          open={openLogin}
          className={`modal`}
          style={{ background: "rgba(0, 0, 0, 0.4)" }}
        >
          <div className="modal-box max-w-4xl bg-white text-gray-800 dark:bg-gray-900 dark:text-white">
            <div className="border-stroke shadow-default dark:border-strokedark dark:bg-boxdark rounded-sm">
              <div className="flex flex-nowrap items-center gap-5">
                <div className="hidden w-full xl:block xl:w-1/2">
                  <div className="carousel h-[80vh] w-full overflow-hidden">
                    {slides.map((slide, index) => (
                      <div
                        key={index}
                        className={`carousel-item relative  w-full ${
                          index === activeSlide ? "block" : "hidden"
                        }`}
                      >
                        <div className="absolute top-0 w-full">
                          <img
                            src={slide.image}
                            className="h-[100vh] w-full object-cover"
                            alt={`Slide ${index + 1}`}
                          />
                          <div
                            className="absolute top-0 z-10 h-full w-full opacity-60"
                            style={{ background: "black" }}
                          ></div>

                          <div className="absolute inset-0 z-10 flex flex-col items-center justify-center text-white">
                            <h1 className="text-center font-poppins text-3xl font-semibold text-[#bb8e25]">
                              Kataria tours & travel
                            </h1>
                            <p className="mt-2 text-center font-light text-gray-300">
                              {slide.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="border-stroke dark:border-strokedark w-full xl:w-1/2 xl:border-l-2">
                  <div className="sm:p-12.5 xl:p-17.5 w-full p-4">
                    <LoginForm
                      closeButton={
                        <Crossicon onClose={() => setOpenLogin(false)} />
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </dialog>
      )}

      {/* SignUp Modal */}
      {/* <SignUp open={openSignUp} setOpen={setOpenSignUp} /> */}
    </>
  );
}
