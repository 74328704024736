import React, { useEffect, useState } from "react";
import Select from "react-tailwindcss-select";
import { useForm, useFieldArray } from "react-hook-form";
import { FaCalendarAlt, FaPlaneDeparture } from "react-icons/fa";
import Crossicon from "components/CrossIcon/Crossicon";
import InputField from "components/fields/InputField";
import FormDropdown from "components/FormDropdown/FormDropdown";
import getSelectClassNames from "components/SelectFieldClasses/SelectFieldClasses";
import userResources from "api/userResources";
import { toast } from "react-toastify";
import SubmitButton from "components/SubmitButton/SubmitButton";
import { useLocation } from "react-router-dom";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";

const options = [
  {
    label: "India",
    options: [
      {
        value: "Indira Gandhi International Airport (New Delhi)",
        label: "Indira Gandhi International Airport (New Delhi)",
      },
      {
        value: "Chhatrapati Shivaji International Airport (Mumbai)",
        label: "Chhatrapati Shivaji International Airport (Mumbai)",
      },
      {
        value: "Kempegowda International Airport (Bengaluru)",
        label: "Kempegowda International Airport (Bengaluru)",
      },
    ],
  },
  {
    label: "🍁 Canada",
    options: [
      {
        value: "Calgary International Airport",
        label: "Calgary International Airport",
      },
      {
        value: "St. John's International Airport",
        label: "St. John's International Airport",
      },
      {
        value: "Toronto Pearson International Airport",
        label: "Toronto Pearson International Airport",
      },
    ],
  },
  {
    label: "America",
    options: [{ value: "America", label: "America" }],
  },
];
// const airlineData = [
//   { value: "", label: "Select Preferred Airline" },
//   { value: "American Airlines", label: "American Airlines" },
//   { value: "Delta Air Lines", label: "Delta Air Lines" },
//   { value: "United Airlines", label: "United Airlines" },
//   { value: "Southwest Airlines", label: "Southwest Airlines" },
//   { value: "JetBlue Airways", label: "JetBlue Airways" },
//   { value: "Alaska Airlines", label: "Alaska Airlines" },
//   { value: "Air Canada", label: "Air Canada" },
//   { value: "British Airways", label: "British Airways" },
//   { value: "Lufthansa", label: "Lufthansa" },
//   { value: "Emirates", label: "Emirates" },
// ];

// const enquirySourceData = [
//   { value: "", label: "Select Source" },
//   { value: "website", label: "Website" },
//   { value: "phone", label: "Phone" },
//   { value: "email", label: "Email" },
//   { value: "social_media", label: "Social Media" },
//   { value: "other", label: "Other" },
// ];

// const statusData = [
//   { value: "", label: "Select Status" },
//   { value: "follow_up", label: "Follow-Up" },
//   { value: "open", label: "Open" },
//   { value: "closed", label: "Closed" },
//   { value: "in_progress", label: "In Progress" },
//   { value: "pending", label: "Pending" },
//   { value: "completed", label: "Completed" },
// ];

// const paymentStatusData = [
//   { value: "", label: "Select Payment Status" },
//   { value: "paid", label: "Paid" },
//   { value: "pending", label: "Pending" },
//   { value: "refunded", label: "Refunded" },
// ];

// const employeeData = [
//   { value: "", label: "Select Assigned To" },
//   { value: "Employee1", label: "Employee1" },
//   { value: "Employee2", label: "Employee2" },
//   { value: "Employee3", label: "Employee3" },
// ];

export default function MultiCity() {
  const location = useLocation();
  console.log(location, "multi");

  const {
    register,
    handleSubmit,
    control,
    setValue,
    clearErrors,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "packages",
  });

  const [editing, setEditing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [airportsFrom, setAirportsFrom] = useState({});
  const [airportsTo, setAirportsTo] = useState({});
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(2);
  const [infants, setInfants] = useState(0);
  const [selectedFlightType, setSelectedFlightType] = useState("economy");
  const [departure_date, setDeparture_date] = useState([
    new Date().toISOString().split("T")[0],
  ]); // Initial state as an array of dates
  const [employeeData, setEmployeeData] = useState([]);
  const [airlineData, setAirlineData] = useState([]);
  const [enquirySourceData, setEenquirySourceData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [paymentStatusData, setPaymentStatusData] = useState([]);

  const From = (value, index) => {
    setAirportsFrom((prev) => ({
      ...prev,
      [index]: value,
    }));
    clearErrors(`packages.${index}.from`); // Clear the error for this specific field
    // Set the value for "from" field in the form data
    setValue(`packages[${index}].from`, value.value);
  };
  const To = (value, index) => {
    setAirportsTo((prev) => ({
      ...prev,
      [index]: value,
    }));
    clearErrors(`packages.${index}.to`); // Clear the error for this specific field
    // Set the value for "from" field in the form data
    setValue(`packages[${index}].to`, value.value);
  };

  const departFun = (e, index) => {
    const updatedDepart = [...departure_date]; // Copy the current state
    updatedDepart[index] = e.target.value; // Update the specific index
    setDeparture_date(updatedDepart); // Update the state

    clearErrors(`packages.${index}.departure_date`); // Clear the error for this specific field
    // Set the value for "from" field in the form data
    setValue(`packages[${index}].departure_date`, e.target.value);
  };
  const handleFlightTypeChange = (event) => {
    setSelectedFlightType(event.target.value);
  };

  const onSubmit = async (data) => {
    // Disable the button during submission
    setDisabled(true);

    // Display a loading toast
    const loadingToast = toast.loading("Submitting your enquiry...");

    const finalData = {
      ...data,
      adult: parseInt(adults),
      child: parseInt(children),
      infant: parseInt(infants),
      class_of_travel: selectedFlightType,
      package_type: "multi_city",
    };

    try {
      const response = await userResources.createEnquiryMultiWay(finalData);
      console.log(response);

      // Update the loading toast to success
      toast.update(loadingToast, {
        render: "Enquiry submitted successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error(error);

      // Update the loading toast to error
      toast.update(loadingToast, {
        render: "Failed to submit enquiry. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      // Re-enable the button after submission is complete
      setDisabled(false);
      reset();
    }
  };

  useEffect(() => {
    append({
      from: "",
      to: "",
      departure_date: new Date().toISOString().split("T")[0],
    });
    append({
      from: "",
      to: "",
      departure_date: new Date().toISOString().split("T")[0],
    });
  }, [append]);

  // Function to fetch all necessary data
  const fetchData = async () => {
    try {
      // Define an array of API calls
      const apiCalls = [
        {
          method: userResources.userList,
          params: { role_id: Number(process.env.REACT_APP_ROLE_EMPLOYEE) },
          callback: setEmployeeData,
          errorMsg: "User list API call failed:",
        },
        {
          method: userResources.airlines,
          params: { page: 0, size: 99999 },
          callback: setAirlineData,
          errorMsg: "Airlines API call failed:",
        },
        {
          method: userResources.getSources,
          // params: {},
          callback: setEenquirySourceData,
          errorMsg: "Error fetching Enquiry Source data:",
        },
        {
          method: userResources.enquityStatus,
          // params: {},
          callback: setStatusData,
          errorMsg: "Error fetching Enquiry Status data:",
        },
        {
          method: userResources.paymentStatus,
          // params: {},
          callback: setPaymentStatusData,
          errorMsg: "Error fetching Payment Status data:",
        },
      ];

      // Call all APIs simultaneously using Promise.allSettled
      const results = await Promise.allSettled(
        apiCalls.map((api) => api.method(api.params))
      );

      // Process each result
      results.forEach((result, index) => {
        if (result.status === "fulfilled" && result.value?.status) {
          const data = result.value.data.data.map((item) => ({
            value: item.id,
            label: item.name || item.airline_name, // Use item.airline_name if it exists
          }));
          apiCalls[index].callback(data);
        } else {
          console.log(apiCalls[index].errorMsg, result.reason);
        }
      });
    } catch (error) {
      console.log("An unexpected error occurred:", error);
    }
  };

  // Call fetchData inside useEffect
  useEffect(() => {
    fetchData();
  }, []);

  const handleChange = (event) => {
    // Clear error when user types in the input field
    clearErrors(event);
  };

  return (
    <section className="my-2 max-w-full py-2 sm:my-0 sm:mb-5 ">
      <ScrollToTopOnRouteChange />

      <form onSubmit={handleSubmit(onSubmit)} className="hide-scroll-bar  ">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <InputField
            label="Enter Title"
            name="title"
            register={register}
            placeholder="Enter Title"
            state={errors.title ? "error" : ""} // Update state based on validation
            disabled={false}
            extra="w-full"
            rules={{ required: "Title is required." }} // Set validation rules
            errorMessage={errors.title?.message}
            onChange={() => handleChange("title")} /// Pass error message from React Hook Form
          />

          <InputField
            label="Customer Name"
            name="customer_name"
            register={register}
            placeholder="Customer Name"
            state="" // or "success"
            disabled={false}
            extra="w-full mb-4"
            rules={{ required: "Customer name is required." }} // Set validation rules
            errorMessage={errors.customer_name?.message}
            onChange={() => handleChange("customer_name")}
          />
        </div>

        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <InputField
            label="Email Address"
            name="email"
            register={register}
            placeholder="Customer Email"
            state="" // or "success"
            disabled={false}
            extra="w-full mb-4"
            rules={{ required: "email is required." }} // Set validation rules
            errorMessage={errors.email?.message}
            onChange={() => handleChange("email")}
          />
          <InputField
            label="Phone Number"
            type="number"
            name="phone_number"
            register={register}
            placeholder="Phone Number"
            state="" // or "success"
            disabled={false}
            extra="w-full mb-4"
            rules={{ required: "Phone number is required." }} // Set validation rules
            errorMessage={errors.phone_number?.message}
            onChange={() => handleChange("phone_number")}
          />
        </div>

        {fields.map((city, index) => (
          <div
            key={city.id}
            className=" grid grid-cols-1 rounded-md border border-gray-700 bg-gray-900 pb-4 text-white sm:grid-cols-2 sm:py-5 sm:pb-0 md:grid-cols-4 "
          >
            <div className=" border-b border-r border-gray-700 border-b-white px-3 py-4 sm:border-b-0 sm:py-0">
              <div className="flex items-center gap-1">
                <FaPlaneDeparture />
                <label className="text-md text-yellow-500">FROM</label>
              </div>
              <Select
                {...register(`packages.${index}.from`, {
                  required: "This is required.",
                })}
                value={airportsFrom[index]}
                onChange={(value) => From(value, index)}
                options={options}
                isSearchable={true}
                placeholder="Select..."
                loading={false}
                classNames={{
                  menuButton: ({ isDisabled }) =>
                    `flex gap-5 pl-3 sm:pl-1 sm:pl-0 w-full cursor-pointer text-white relative border-0 text-lg overflow-hidden text-gray-900 transition-all outline-none duration-300 focus:outline-none cursor-pointer ${
                      isDisabled
                        ? "bg-gray-200 "
                        : "bg-transparent hover:border-gray-400 cursor-pointer"
                    }`,
                  menu: "absolute z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                  listItem: ({ isSelected }) =>
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                      isSelected
                        ? `text-white bg-blue-500`
                        : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`,
                }}
                formatGroupLabel={(data) => (
                  <div
                    className={`flex items-center justify-between py-2 text-xs`}
                  >
                    <span className="font-bold">{data.label}</span>
                    <span className="flex h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                      {data.options.length}
                      👉 Airports
                    </span>
                  </div>
                )}
                formatOptionLabel={(data) => (
                  <li
                    className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                      !data.isSelected
                        ? `bg-gray-200 text-gray-900`
                        : `bg-blue-800 text-gray-200`
                    }`}
                  >
                    {data.label}
                  </li>
                )}
              />

              {errors.packages?.[index]?.from && (
                <span className="text-sm text-red-500">
                  {errors.packages[index].from.message}
                </span>
              )}
            </div>
            <div className="border-b border-r border-gray-700 border-b-white px-3 py-4 sm:border-b-0 sm:py-0 ">
              <div className="flex items-center gap-1">
                <FaPlaneDeparture />
                <label className="text-md text-yellow-500">To</label>
              </div>
              <Select
                {...register(`packages.${index}.to`, {
                  required: "This is required.",
                })}
                value={airportsTo[index]}
                onChange={(value) => To(value, index)}
                options={options}
                isSearchable={true}
                placeholder="Select..."
                loading={false}
                classNames={{
                  menuButton: ({ isDisabled }) =>
                    `flex  w-full pl-4 sm:pl-0  cursor-pointer text-white relative  border-0 text-lg overflow-hidden text-gray-900 transition-all outline-none duration-300 focus:outline-none cursor-pointer ${
                      isDisabled
                        ? "bg-gray-200"
                        : "bg-transparent hover:border-gray-400"
                    }`,
                  menu: "absolute z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                  listItem: ({ isSelected }) =>
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                      isSelected
                        ? `text-white bg-blue-500`
                        : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`,
                }}
                formatGroupLabel={(data) => (
                  <div
                    className={`flex items-center justify-between py-2 text-xs`}
                  >
                    <span className="font-bold">{data.label}</span>
                    <span className="flex  h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                      {data.options.length}
                      👉 Airpots
                    </span>
                  </div>
                )}
                formatOptionLabel={(data) => (
                  <li
                    className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                      !data.isSelected
                        ? `bg-gray-200 text-gray-900`
                        : `bg-blue-800 text-gray-200`
                    }`}
                  >
                    {/* // data represents each option in the list */}
                    {data.label}
                  </li>
                )}
              />
              {errors.packages?.[index]?.to && (
                <span className="text-sm text-red-500">
                  {errors.packages[index].to.message}
                </span>
              )}
            </div>

            <div className="border-r border-gray-700 px-3 py-4 pb-0 sm:py-0 sm:pb-2">
              <div className="flex items-center gap-2">
                <FaCalendarAlt />
                <label className="text-md text-yellow-500">DEPART</label>
              </div>
              <input
                type="date"
                {...register(`packages.${index}.departure_date`, {
                  required: "This is required.",
                })}
                className="z-50 mt-2 w-full cursor-pointer rounded-sm bg-transparent bg-white p-2 text-gray-900 outline-0"
                value={departure_date[index] || ""} // Use the value from state or default to empty
                onChange={(e) => departFun(e, index)} // Update the date on change
                min={
                  index === 0
                    ? new Date().toISOString().split("T")[0] // Today's date for the first field
                    : departure_date[index - 1] ||
                      new Date().toISOString().split("T")[0] // Previous field's date or today
                }
              />
              {errors.packages?.[index]?.departure_date && (
                <span className="text-sm text-red-500">
                  {errors.packages[index].departure_date.message}
                </span>
              )}
            </div>

            <div className="flex justify-between px-3">
              {index === 0 && (
                <div className="mt-3 sm:mt-0">
                  <div className="flex items-center gap-2">
                    <FaCalendarAlt />
                    <label className="text-md text-yellow-500">
                      TRAVELLERS
                    </label>
                  </div>
                  <div className=" dropdown ml-6 w-full sm:ml-0">
                    <div tabIndex={0} role="button" className="cursor-pointer">
                      <h3 className="text-lg">
                        {adults + children + infants} Travellers
                      </h3>
                      <p className="text-sm">{selectedFlightType}</p>
                    </div>
                    <div
                      tabIndex={0}
                      className="menu dropdown-content z-[1] w-52 rounded-box bg-white p-2 text-gray-900 shadow"
                    >
                      <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100 ">
                        <div className="flex items-center justify-between ">
                          <h4>Adults</h4>
                          <h4>{adults}</h4>
                          <div className="flex items-center rounded-md border">
                            <div
                              className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => {
                                if (adults > 1) {
                                  setAdults(adults - 1);
                                }
                              }}
                            >
                              -
                            </div>
                            <div
                              className="cursor-pointer border border-s-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => setAdults(adults + 1)}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                        <div className="flex items-center justify-between">
                          <div>
                            <h4>Children</h4>
                            <h6 className=" text-gray-400">2-12 Years</h6>
                          </div>
                          <h4>{children}</h4>
                          <div className="flex items-center rounded-md border">
                            <div
                              className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => {
                                if (children > 0) {
                                  setChildren(children - 1);
                                }
                              }}
                            >
                              -
                            </div>
                            <div
                              className="cursor-pointer border border-s-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => setChildren(children + 1)}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                        <div className="flex items-center justify-between">
                          <div>
                            <h4>Infants</h4>
                            <h6 className=" text-gray-400">0-23 Months</h6>
                          </div>
                          <h4>{infants}</h4>
                          <div className="flex items-center rounded-md border">
                            <div
                              className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => {
                                if (infants > 0) {
                                  setInfants(infants - 1);
                                }
                              }}
                            >
                              -
                            </div>
                            <div
                              className="cursor-pointer border border-s-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => setInfants(infants + 1)}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex cursor-pointer items-center gap-2 rounded-md px-1 py-1 hover:bg-gray-100">
                          <input
                            type="radio"
                            id="economy"
                            name="flightType"
                            value="economy"
                            defaultChecked
                            onChange={handleFlightTypeChange}
                          />
                          <label htmlFor="economy" className="cursor-pointer">
                            Economy
                          </label>
                        </div>
                        <div className="flex cursor-pointer items-center gap-2 rounded-md px-1 py-1 hover:bg-gray-100">
                          <input
                            type="radio"
                            id="premiumEconomy"
                            name="flightType"
                            value="premium_economy"
                            onChange={handleFlightTypeChange}
                          />
                          <label
                            htmlFor="premiumEconomy"
                            className="cursor-pointer"
                          >
                            Premium Economy
                          </label>
                        </div>
                        <div className="flex cursor-pointer items-center gap-2 rounded-md px-1 py-1 hover:bg-gray-100">
                          <input
                            type="radio"
                            id="business"
                            name="flightType"
                            value="business"
                            onChange={handleFlightTypeChange}
                          />
                          <label htmlFor="business" className="cursor-pointer">
                            Business
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-6 sm:mt-8">
                {index === fields.length - 1 &&
                  index !== 0 &&
                  fields.length <= 6 && (
                    <div className="">
                      <button
                        type="button"
                        className="add-city-inner"
                        onClick={() => {
                          append({
                            from: "",
                            to: "",
                            departure_date: "",
                          });
                        }}
                      >
                        Add City
                      </button>
                    </div>
                  )}
              </div>
              <div className="mt-6 flex cursor-pointer items-center justify-center sm:mt-8 ">
                {fields.length > 2 && (
                  <div
                    onClick={() => remove(index)}
                    className="flex h-full  cursor-pointer items-center justify-center  px-2 text-center text-xs "
                  >
                    <Crossicon />
                    {/* REMOVE */}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        {fields.length <= 1 ? (
          <div className="relative float-right -mt-2">
            <button
              type="button"
              className="add-city"
              onClick={() => {
                append({
                  from: "",
                  to: "",
                  departure_date: "",
                });
              }}
            >
              Add City
            </button>
          </div>
        ) : (
          ""
        )}

        <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div>
            <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
              Enquiry Source
            </label>
            <Select
              {...register(`enquiry_source_id`, {
                required: "This is required.",
              })}
              value={enquirySourceData.find(
                (source) => source.value === watch("enquiry_source_id") // Use watch to get the current value
              )} // Controlled value
              onChange={(selectedOption) => {
                setValue("enquiry_source_id", selectedOption?.value || ""); // Update handler using setValue from React Hook Form
                clearErrors("enquiry_source_id");
              }}
              options={enquirySourceData} // Format options
              isSearchable={true} // Enable search functionality
              placeholder="Select..."
              classNames={getSelectClassNames({
                isDisabled: false,
                selectProps: {},
                isSelected: false,
                value: watch("enquiry_source_id"), // Use watch to get the current value
              })} // Pass parameters here
              formatOptionLabel={(data) => (
                <li
                  className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                    !data.isSelected
                      ? `bg-gray-200 text-gray-900`
                      : `bg-blue-800 text-gray-200`
                  }`}
                >
                  {data.label}
                </li>
              )}
            />
            {errors.enquiry_source_id && (
              <span className="text-sm text-red-500">
                {errors.enquiry_source_id.message}
              </span>
            )}
          </div>
          <InputField
            label="Enquiry Type"
            type="text" // Use type "text" or adjust as needed
            name="type"
            placeholder="Enquiry Type"
            // Match the variant with the other inputs
            extra="w-full" // Match the layout classes if necessary
            state="" // Optional: If you have state like "error" or "success"
            disabled={false} // Optional: Update based on the form state
            register={register}
            rules={{ required: "Enquiry Type is required." }} // Set validation rules
            errorMessage={errors.type?.message}
            onChange={() => handleChange("type")}
          />
        </div>
        <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div>
            <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
              Preferred Airline
            </label>
            <Select
              {...register(`air_line_id`, {
                required: "This is required.",
              })}
              value={airlineData.find(
                (source) => source.value === watch("air_line_id") // Use watch to get the current value
              )} // Controlled value
              onChange={(selectedOption) => {
                setValue("air_line_id", selectedOption?.value || ""); // Update handler using setValue from React Hook Form
                clearErrors("air_line_id");
              }}
              options={airlineData} // Format options
              isSearchable={true} // Enable search functionality
              placeholder="Select..."
              classNames={getSelectClassNames({
                isDisabled: false,
                selectProps: {},
                isSelected: false,
                value: watch("air_line_id"), // Use watch to get the current value
              })} // Pass parameters here
              formatOptionLabel={(data) => (
                <li
                  className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                    !data.isSelected
                      ? `bg-gray-200 text-gray-900`
                      : `bg-blue-800 text-gray-200`
                  }`}
                >
                  {data.label}
                </li>
              )}
            />
            {errors.air_line_id && (
              <span className="text-sm text-red-500">
                {errors.air_line_id.message}
              </span>
            )}
          </div>

          <InputField
            label="Booking Reference"
            type="text" // Use type "text" or adjust as needed
            name="booking_reference"
            placeholder="Enquiry Type"
            // Match the variant with the other inputs
            extra="w-full" // Match the layout classes if necessary
            state="" // Optional: If you have state like "error" or "success"
            disabled={false} // Optional: Update based on the form state
            register={register}
            rules={{ required: "Booking Reference is required." }} // Set validation rules
            errorMessage={errors.booking_reference?.message}
            onChange={() => handleChange("booking_reference")}
          />
        </div>
        <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
          <InputField
            label="Budget"
            type="number" // Use type "text" or adjust as needed (e.g., "number" if you prefer)
            placeholder="Budget"
            name="budget"
            // Match the variant with the other inputs
            extra="w-full"
            state={""} // Optional: If you have state like "error" or "success"
            disabled={false} // Optional: Update based on the form state
            register={register}
            rules={{ required: "budget is required." }} // Set validation rules
            errorMessage={errors.budget?.message}
            onChange={() => handleChange("budget")}
          />

          {/* Status */}
          <div>
            <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
              Status
            </label>
            <Select
              {...register(`enquiry_status_id`, {
                required: "This is required.",
              })}
              value={statusData.find(
                (status) => status.value === watch("enquiry_status_id") // Use watch to get the current value from react-hook-form
              )} // Controlled value
              onChange={(selectedOption) => {
                setValue("enquiry_status_id", selectedOption?.value || ""); // Update handler using setValue from React Hook Form
                clearErrors("enquiry_status_id");
              }}
              options={statusData} // Options for the status dropdown
              isSearchable={true} // Enable search functionality
              placeholder="Select Status"
              classNames={getSelectClassNames({
                isDisabled: false,
                selectProps: {}, // Select props, if any
                isSelected: false,
                value: watch("enquiry_status_id"), // Use watch to get the current value
              })} // Pass parameters here
              formatOptionLabel={(data) => (
                <li
                  className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                    !data.isSelected
                      ? `bg-gray-200 text-gray-900`
                      : `bg-blue-800 text-gray-200`
                  }`}
                >
                  {data.label}
                </li>
              )}
            />
            {errors.enquiry_status_id && (
              <span className="text-sm text-red-500">
                {errors.enquiry_status_id.message}
              </span>
            )}
          </div>
        </div>
        <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
          <InputField
            label="Invoice Number"
            type="text" // Use type "text" or adjust as needed (e.g., "number" if you prefer)
            placeholder="Invoice Number"
            name="invoice_number"
            // Match the variant with the other inputs
            extra="w-full"
            state={""} // Optional: If you have state like "error" or "success"
            disabled={false} // Optional: Update based on the form state
            register={register}
            rules={{ required: "Invoice Number is required." }} // Set validation rules
            errorMessage={errors.invoice_number?.message}
            onChange={() => handleChange("invoice_number")}
          />
          <InputField
            label="Paid Amount"
            type="number" // Use type "text" or adjust as needed (e.g., "number" if you prefer)
            placeholder="Paid Amount"
            name="paid_amount"
            // Match the variant with the other inputs
            extra="w-full"
            state={""} // Optional: If you have state like "error" or "success"
            disabled={false} // Optional: Update based on the form state
            register={register}
            rules={{ required: "Paid Amount is required." }} // Set validation rules
            errorMessage={errors.paid_amount?.message}
            onChange={() => handleChange("paid_amount")}
          />
        </div>
        <div className="mt-4 grid grid-cols-2 gap-4">
          {/* Payment Status */}
          <div>
            <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
              Payment Status
            </label>
            <Select
              {...register(`enquiry_payment_status_id`, {
                required: "Payment status is required.",
              })}
              value={paymentStatusData.find(
                (option) => option.value === watch("enquiry_payment_status_id") // Use watch to get the current value from React Hook Form
              )} // Controlled value
              onChange={(selectedOption) => {
                setValue(
                  "enquiry_payment_status_id",
                  selectedOption?.value || ""
                ); // Update the value using setValue from React Hook Form
                clearErrors("enquiry_payment_status_id");
              }}
              options={paymentStatusData} // Format options
              isSearchable={true} // Enable search functionality
              placeholder="Select Payment Status"
              classNames={getSelectClassNames({
                isDisabled: false,
                selectProps: {},
                isSelected: false,
                value: watch("enquiry_payment_status_id"), // Use watch to get the current value
              })} // Pass parameters here
              formatOptionLabel={(data) => (
                <li
                  className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                    !data.isSelected
                      ? `bg-gray-200 text-gray-900`
                      : `bg-blue-800 text-gray-200`
                  }`}
                >
                  {data.label}
                </li>
              )}
            />
            {errors.enquiry_payment_status_id && (
              <span className="text-sm text-red-500">
                {errors.enquiry_payment_status_id.message}
              </span>
            )}
          </div>

          {/* Assigned To */}

          <div>
            <label className="my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25]">
              Assigned To
            </label>
            <Select
              {...register(`assigned_to_user_id`, {
                required: "Please select a user to assign this enquiry.",
              })}
              value={employeeData.find(
                (option) => option.value === watch("assigned_to_user_id") // Use watch to get the current value from React Hook Form
              )} // Controlled value
              onChange={(selectedOption) => {
                setValue("assigned_to_user_id", selectedOption?.value || ""); // Update the value using setValue from React Hook Form
                clearErrors("assigned_to_user_id");
              }}
              options={employeeData} // Format options
              isSearchable={true} // Enable search functionality
              placeholder="Select Assigned To"
              classNames={getSelectClassNames({
                isDisabled: false,
                selectProps: {},
                isSelected: false,
                value: watch("assigned_to_user_id"), // Use watch to get the current value
              })} // Pass parameters here
              formatOptionLabel={(data) => (
                <li
                  className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                    !data.isSelected
                      ? `bg-gray-200 text-gray-900`
                      : `bg-blue-800 text-gray-200`
                  }`}
                >
                  {data.label}
                </li>
              )}
            />
            {errors.assigned_to_user_id && (
              <span className="text-sm text-red-500">
                {errors.assigned_to_user_id.message}
              </span>
            )}
          </div>
        </div>
        <div className="mt-4 grid grid-cols-1 gap-0 sm:grid-cols-2 sm:gap-4">
          <InputField
            label="Remark"
            id="remark" // Add an id for accessibility
            name="remark"
            type="text" // Although not necessary for textarea, can be used for consistency
            placeholder="Remark"
            // Match the variant with the other inputs
            extra="col-span-2 sm:col-span-1" // Match the layout classes if necessary
            state="" // Optional: If you have state like "error" or "success"
            disabled={false} // Optional: Update based on the form state
            register={register}
            isTextArea={true} // Indicate that this is a textarea
          />

          <div className="flex w-full justify-center">
            <SubmitButton
              editing={editing}
              disabled={disabled}
              add="Submit"
              edit="Update"
              extraClass="btn mt-4 w-full border-0 sm:mt-9"
            />
          </div>
        </div>
      </form>
    </section>
  );
}
