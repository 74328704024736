import katariatravels from "../assets/img/auth/katariatravels.png";
import avtar from "../assets/img/avatars/avtar.jpeg";
import hero_background from "../assets/img/Home/background/hero_background.jpg";
import logo1 from "../assets/img/Home/Logo/logo1.png";
import logo2 from "../assets/img/Home/Logo/logo2.png";
import logo3 from "../assets/img/Home/Logo/logo3.png";
import LoginCrasoualImage1 from "../assets/img/Home/LoginCrasoualImage/LoginCrasoualImage1.jpeg";
import LoginCrasoualImage2 from "../assets/img/Home/LoginCrasoualImage/LoginCrasoualImage2.jpeg";
import LoginCrasoualImage3 from "../assets/img/Home/LoginCrasoualImage/LoginCrasoualImage3.jpeg";
import LoginCrasoualImage4 from "../assets/img/Home/LoginCrasoualImage/LoginCrasoualImage4.jpeg";

export default katariatravels;
export {
  avtar,
  hero_background,
  logo1,
  logo2,
  logo3,
  LoginCrasoualImage1,
  LoginCrasoualImage2,
  LoginCrasoualImage3,
  LoginCrasoualImage4
};
