// selectClassNames.js

// Function to generate select class names based on parameters
const getSelectClassNames = ({
  isDisabled,
  selectProps,
  isSelected,
  value,
  errorsBorder,
}) => ({
  menuButton: () => {
    return `flex w-full ${
      value === "" || value === undefined
        ? "text-gray-400"
        : "text-gray-900 dark:text-white"
    } rounded-md ${
      errorsBorder ? "border-red-500" : ""
    } cursor-pointer relative border dark:!border-white/10 text-md overflow-hidden transition-all outline-none duration-300 focus:outline-none ${
      isDisabled ? "bg-gray-200" : "bg-gray-50 dark:bg-transparent"
    }`;
  },
  menu: "absolute top-10 z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
  listItem: () => {
    console.log("Is Selected:", isSelected); // Print the isSelected value to the console
    return `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
      isSelected
        ? "text-white"
        : "text-gray-500 hover:bg-blue-100 hover:text-blue-500"
    }`;
  },
});

// Export the function
export default getSelectClassNames;
