import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";

import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import AgencyLogin from "Auth/AgencyLogin/AgencyLogin";
import { selectUserInfo } from "../src/redux/userSlice";
import { useSelector } from "react-redux";
import Home from "Pages/Home/Home";
import Navbar from "components/Home/Navbar/Navbar";
import Flight from "Pages/Flight/Flight";
import Car from "Pages/Car/Car";
import Oneway from "components/Home/FlightRoutes/Oneway";
import MultiCity from "components/Home/FlightRoutes/MultiCity";
import RoundTrip from "components/Home/FlightRoutes/RoundTrip";
import HomeHotel from "Pages/Hotel/HomeHotel";
import EnquaryHistory from "components/EnquaryHistory/EnquaryHistory";

const App = () => {
  const navigate = useNavigate(); // lowercase 'n'
  const location = useLocation();
  const userInfo = useSelector(selectUserInfo);
  const [isVisible, setIsVisible] = useState(true);

  const adminRole = Number(process.env.REACT_APP_ROLE_ADMIN);
  const employeeRole = Number(process.env.REACT_APP_ROLE_EMPLOYEE);
  const superAdminRole = Number(process.env.REACT_APP_ROLE_SUPERADMIN);

  const isAdmin =
    userInfo?.data?.role_id === adminRole ||
    userInfo?.data?.role_id === employeeRole ||
    userInfo?.data?.role_id === superAdminRole;

  // Scroll event handler to toggle visibility
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isAdmin ? (
        <>
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/admin/default" replace />}
            />
            <Route
              path="/dashboard"
              element={<Navigate to="/admin" replace />}
            />
            <Route path="auth/*" element={<AuthLayout />} />
            <Route path="admin/*" element={<AdminLayout />} />
            <Route path="admin/enquiry-history" element={<EnquaryHistory />} />
            {/* <Route path="/create-enquiry" element={<CreateEnquiry />}>
            <Route index element={<RoundTrip />} />
            <Route path="oneWay" element={<OneWay />} />
            <Route path="round-trip" element={<RoundTrip />} />
            <Route path="multi-city" element={<MultiCity />} />
          </Route> */}
          </Routes>
        </>
      ) : (
        <>
          {location.pathname !== "/agencyLogin" && <Navbar />}
          <Routes>
            <Route path="/" element={<Home />}>
              <Route index element={<Flight />} />
              <Route path="/flights" element={<Flight />}>
                <Route index element={<RoundTrip />} />
                <Route path="oneWay" element={<Oneway />} />
                <Route path="round-trip" element={<RoundTrip />} />
                <Route path="multi-city" element={<MultiCity />} />
              </Route>
              <Route path="/hotels" element={<HomeHotel />} />
              <Route path="/cars" element={<Car />} />
              {/* <Route path="/hotdeals" element={<Hotdeals />} /> */}
            </Route>
            <Route path="/agencyLogin" element={<AgencyLogin />} />
          </Routes>
        </>
      )}
    </>
  );
};

export default App;
