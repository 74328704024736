import React from "react";
import { useLocation, Link, Outlet, useParams } from "react-router-dom";
import { FaPlane, FaUmbrellaBeach, FaCar } from "react-icons/fa"; // Import relevant icons
import RowRoutesIcon from "components/RowRoutesIcon/RowRoutesIcon";

export default function CreateEnquiry() {
  const location = useLocation();
  // This will capture the current route path

  // Define routes for the tabs with icons
  const routes = [
    {
      label: "Flight",
      url: "/admin/create-enquiry/flight/round-trip",
      icon: <FaPlane />,
      bg: [
        "/admin/create-enquiry/flight/round-trip",
        "/admin/create-enquiry/flight/oneway",
        "/admin/create-enquiry/flight/multi-city",
      ],
    },
    {
      label: "Hotel",
      url: "/admin/create-enquiry/hotel",
      icon: <FaUmbrellaBeach />,
      bg: ["/admin/create-enquiry/hotel"],
    },
    {
      label: "Cars",
      url: "/admin/create-enquiry/cars",
      icon: <FaCar />,
      bg: ["/admin/create-enquiry/cars"],
    },
  ];

  return (
    <section className="mt-6 bg-white p-2 dark:bg-transparent sm:p-4">
      <RowRoutesIcon routes={routes} />
      <div className="my-5 rounded-md bg-transparent ">
        <Outlet />
      </div>
    </section>
  );
}
