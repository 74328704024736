import React, { useEffect, useState } from "react";
import Select from "react-tailwindcss-select";
import { FaCalendarAlt } from "react-icons/fa";
import InputField from "components/fields/InputField";
import FormDropdown from "components/FormDropdown/FormDropdown";
import userResources from "api/userResources";
import getSelectClassNames from "components/SelectFieldClasses/SelectFieldClasses";
import SubmitButton from "components/SubmitButton/SubmitButton";
import { toast } from "react-toastify";

const options = [
  {
    label: "India",
    options: [
      {
        value: "Indira Gandhi International Airport (New Delhi)",
        label: "Indira Gandhi International Airport (New Delhi)",
      },
      {
        value: "Chhatrapati Shivaji Maharaj International Airport (Mumbai)",
        label: "Chhatrapati Shivaji Maharaj International Airport (Mumbai)",
      },
      {
        value: "Kempegowda International Airport (Bangalore)",
        label: "Kempegowda International Airport (Bangalore)",
      },
      {
        value: "Netaji Subhas Chandra Bose International Airport (Kolkata)",
        label: "Netaji Subhas Chandra Bose International Airport (Kolkata)",
      },
    ],
  },
  {
    label: "Canada",
    options: [
      {
        value: "Toronto Pearson International Airport",
        label: "Toronto Pearson International Airport",
      },
      {
        value: "Vancouver International Airport",
        label: "Vancouver International Airport",
      },
      {
        value: "Montreal-Pierre Elliott Trudeau International Airport",
        label: "Montreal-Pierre Elliott Trudeau International Airport",
      },
      {
        value: "Calgary International Airport",
        label: "Calgary International Airport",
      },
    ],
  },
  {
    label: "United States",
    options: [
      {
        value: "Los Angeles International Airport (LAX)",
        label: "Los Angeles International Airport (LAX)",
      },
      {
        value: "John F. Kennedy International Airport (New York)",
        label: "John F. Kennedy International Airport (New York)",
      },
      {
        value: "O'Hare International Airport (Chicago)",
        label: "O'Hare International Airport (Chicago)",
      },
      {
        value: "Dallas/Fort Worth International Airport (Dallas)",
        label: "Dallas/Fort Worth International Airport (Dallas)",
      },
    ],
  },
  {
    label: "United Kingdom",
    options: [
      {
        value: "Heathrow Airport (London)",
        label: "Heathrow Airport (London)",
      },
      { value: "Gatwick Airport (London)", label: "Gatwick Airport (London)" },
      { value: "Manchester Airport", label: "Manchester Airport" },
      { value: "Edinburgh Airport", label: "Edinburgh Airport" },
    ],
  },
  {
    label: "Australia",
    options: [
      {
        value: "Sydney Kingsford Smith Airport",
        label: "Sydney Kingsford Smith Airport",
      },
      { value: "Melbourne Airport", label: "Melbourne Airport" },
      { value: "Brisbane Airport", label: "Brisbane Airport" },
      { value: "Perth Airport", label: "Perth Airport" },
    ],
  },
];

// Array for Status options

export default function MutaualForm({ thisoneWay, packageType, updateData }) {
  console.log(updateData, "updateData");

  const [editing, setEditing] = useState(updateData ? true : false);
  const [disabled, setDisabled] = useState(false);
  const [airpotsFrom, setAirpotsFrom] = useState(null);
  const [airpotsTo, setAirpotsTo] = useState(null);
  const [departure_date, setDepartureDate] = useState(
    updateData?.departure_date || new Date().toISOString().split("T")[0]
  );
  const [return_date, setReturnDate] = useState(
    thisoneWay
      ? ""
      : updateData?.return_date || new Date().toISOString().split("T")[0]
  );

  const From = (value) => {
    setAirpotsFrom(value);

    // Update formData with the selected airport
    setFormData((prevFormData) => ({
      ...prevFormData,
      from: value?.value || "",
    }));

    // Clear the error for 'from' when a valid value is selected
    setErrors((prevErrors) => ({
      ...prevErrors,
      from: value ? "" : prevErrors.from, // Update to an empty string if there's a value
    }));
  };

  const To = (value) => {
    setAirpotsTo(value);

    // Update formData with the selected airport
    setFormData((prevFormData) => ({
      ...prevFormData,
      to: value?.value || "",
    }));

    // Clear the error for 'to' when a valid value is selected
    setErrors((prevErrors) => ({
      ...prevErrors,
      to: value ? "" : prevErrors.to, // Clear the error if there's a valid selection
    }));
  };

  console.log(thisoneWay, "thisoneWay");

  const [formData, setFormData] = useState({
    customer_name: updateData ? updateData.customer_name : "",
    email: updateData ? updateData.email : "",
    phone_number: updateData ? updateData.phone_number : "",
    from: "",
    to: "",
    departure_date: updateData
      ? updateData.departure_date
      : new Date().toISOString().split("T")[0],
    return_date: thisoneWay ? "" : new Date().toISOString().split("T")[0],
    adult: updateData ? updateData.adult : 1,
    child: updateData ? updateData.child : 0,
    infant: updateData ? updateData.infant : 0,
    class_of_travel: updateData ? updateData.class_of_travel : "economy",
    package_type: updateData ? updateData.package_type : packageType,
  });

  const [errors, setErrors] = useState({
    customer_name: "",
    email: "",
    phone_number: "",
    from: "",
    to: "",
    departure_date: "",
    return_date: "",
    adult: "",
    child: "",
    infant: "",
    class_of_travel: "",
  });

  const validateForm = () => {
    const newErrors = {};

    // Required field validations
    // Add validations for other fields
    if (!formData.from?.trim()) {
      newErrors.from = "Departure airport is required";
    }
    if (!formData.to?.trim()) {
      newErrors.to = "Destination airport is required";
    }
    if (!formData.departure_date?.trim()) {
      newErrors.departure_date = "Departure date is required";
    }
    if (!formData.return_date?.trim() && !thisoneWay) {
      newErrors.return_date = "Return date is required";
    }
    if (formData.adult < 1) {
      newErrors.adult = "At least one adult is required";
    }
    if (formData.child < 0) {
      newErrors.child = "Number of child cannot be negative";
    }
    if (formData.infant < 0) {
      newErrors.infant = "Number of infant cannot be negative";
    }
    if (!formData.class_of_travel?.trim()) {
      newErrors.class_of_travel = "Flight type is required";
    }

    // Set the errors state
    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleDepartDate = (date) => {
    setDepartureDate(date); // Update local state
    setFormData((prev) => ({
      ...prev, // Spread the previous state
      departure_date: date, // Update the departure_date
    }));
  };

  const handleReturnDate = (date) => {
    setReturnDate(date);
    setFormData((prev) => ({
      ...prev, // Spread the previous state
      return_date: date, // Update the departure_date
    }));
  };

  const totalTravellers = formData.adult + formData.child + formData.infant;

  const handleChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const submissionData = { ...formData };

    if (thisoneWay) {
      delete submissionData.return_date;
    }
    console.log("Form Data Submitted:", submissionData);

    if (validateForm()) {
      setDisabled(true); // Disable the button during submission
      toast.loading("Submitting...", { toastId: "loadingToast" }); // Show loading toast

      try {
        const response = await userResources.createEnquiry(submissionData);

        if (response.status) {
          setFormData({
            customer_name: "",
            email: "",
            phone_number: "",
            from: "",
            to: "",
            departure_date: new Date().toISOString().split("T")[0],
            return_date: thisoneWay
              ? ""
              : new Date().toISOString().split("T")[0],
            adult: 1,
            child: 0,
            infant: 0,
            class_of_travel: "economy",
          });
          // Reset additional states
          setAirpotsFrom(null);
          setAirpotsTo(null);
          setDepartureDate(new Date().toISOString().split("T")[0]);
          setReturnDate(
            thisoneWay ? "" : new Date().toISOString().split("T")[0]
          );

          toast.update("loadingToast", {
            render: updateData
              ? "Record updated successfully!"
              : "Submission completed successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        }
        console.log(response);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred. Please try again.";

        toast.update("loadingToast", {
          render: errorMessage,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      } finally {
        setDisabled(false); // Re-enable the button after submission
      }
    }
  };

  return (
    <section className="max-w-full py-2">
      <form onSubmit={handleSubmit} className="">
        <div className="grid grid-cols-1 gap-4">
          {/* <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-4 sm:gap-y-0">
            <InputField
              label="Customer Name" // Ensure to keep the label
              name="customer_name" // Use name to match formData structure
              value={formData.customer_name} // Controlled input for customer name
              onChange={(e) => handleChange("customer_name", e.target.value)} // Handle changes
              placeholder="Customer Name" // Placeholder for the input field
            />
          </div>
          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-4 sm:gap-y-0">
            <InputField
              label="Email Address"
              name="email" // Use name for form data consistency
              type="email" // Set type to "email" for validation
              placeholder="Customer Email"
              variant="auth" // Match the variant with other inputs
              extra="col-span-2 sm:col-span-1" // Match the layout classes
              value={formData.email} // Controlled input
              onChange={(e) => handleChange("email", e.target.value)} // Handle changes
            />
            <InputField
              label="Phone Number"
              name="phone_number" // Use name for form data consistency
              type="tel" // Set type to "tel" for phone number input
              placeholder="Phone Number"
              variant="auth" // Match the variant with other inputs
              extra="col-span-2 sm:col-span-1" // Match the layout classes
              value={formData.phone_number} // Controlled input
              onChange={(e) => handleChange("phone_number", e.target.value)} // Handle changes
            />
          </div> */}

          <div className=" py-5 text-white">
            <div className="grid grid-cols-1 gap-4 px-3 sm:grid-cols-3 md:grid-cols-5">
              <div className="rounded-xl  border-b border-b-gray-100 bg-transparent bg-yellow-500 px-3 py-4 sm:border-b-0">
                <label className="text-md text-white">FROM</label>
                <Select
                  value={airpotsFrom}
                  onChange={From} // Ensure this is your correct handler
                  options={options}
                  isSearchable={true}
                  placeholder="Select..."
                  loading={false}
                  classNames={{
                    menuButton: ({ isDisabled }) =>
                      `flex w-full ${
                        errors.from
                          ? "border-red-500 border text-red-500"
                          : "text-gray-900"
                      }  cursor-pointer mt-2 relative text-lg overflow-hidden transition-all outline-none duration-300 focus:outline-none rounded-sm cursor-pointer ${
                        isDisabled
                          ? "bg-gray-200 border-red-500 "
                          : "bg-white hover:border-gray-400 cursor-pointer"
                      }`,
                    menu: "absolute top-10 z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                    listItem: ({ isSelected }) =>
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                        isSelected
                          ? `text-white bg-blue-500`
                          : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`,
                  }}
                  formatGroupLabel={(data) => (
                    <div
                      className={`flex items-center justify-between py-2 text-xs`}
                    >
                      <span className="font-bold">{data.label}</span>
                      <span className="flex h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                        {data.options.length} 👉 Airports
                      </span>
                    </div>
                  )}
                  formatOptionLabel={(data) => (
                    <li
                      className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                        !data.isSelected
                          ? `bg-gray-200 text-gray-900`
                          : `bg-blue-800 text-gray-200`
                      }`}
                    >
                      {data.label}
                    </li>
                  )}
                />
                {/* Error Message */}
                {errors.from && (
                  <span className="text-xs text-red-500">{errors.from}</span>
                )}
              </div>

              <div className="rounded-xl border-b border-b-gray-100 bg-yellow-500 px-3 py-4 sm:border-b-0">
                <label className="text-md text-white">To</label>
                <Select
                  value={airpotsTo}
                  onChange={To}
                  options={options}
                  isSearchable={true}
                  placeholder="Select..."
                  loading={false}
                  classNames={{
                    menuButton: ({ isDisabled }) =>
                      `flex w-full ${
                        errors.to
                          ? "border-red-500 border text-red-500"
                          : "text-gray-900"
                      }  cursor-pointer mt-2 relative text-lg overflow-hidden transition-all outline-none duration-300 focus:outline-none rounded-sm cursor-pointer ${
                        isDisabled
                          ? "bg-gray-200 border-red-500 "
                          : "bg-white hover:border-gray-400 cursor-pointer"
                      }`,
                    menu: "absolute top-10 z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                    listItem: ({ isSelected }) =>
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                        isSelected
                          ? `text-white bg-blue-500`
                          : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`,
                  }}
                  formatGroupLabel={(data) => (
                    <div
                      className={`flex items-center justify-between py-2 text-xs`}
                    >
                      <span className="font-bold">{data.label}</span>
                      <span className="flex  h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                        {data.options.length}
                        👉 Airpots
                      </span>
                    </div>
                  )}
                  formatOptionLabel={(data) => (
                    <li
                      className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                        !data.isSelected
                          ? `bg-gray-200 text-gray-900`
                          : `bg-blue-800 text-gray-200`
                      }`}
                    >
                      {/* // data represents each option in the list */}
                      {data.label}
                    </li>
                  )}
                />
                {/* Error Message */}
                {errors.to && (
                  <span className="text-xs text-red-500">{errors.to}</span>
                )}
              </div>
              <div className="rounded-xl  border-b border-b-gray-100 bg-yellow-500 px-3 py-4 sm:border-b-0">
                <div className="flex items-center gap-2">
                  <FaCalendarAlt />
                  <label className="text-md text-white">DEPART</label>
                </div>
                <input
                  type="date"
                  className={`z-50 mt-2 w-full cursor-pointer rounded-sm bg-transparent bg-white p-2 text-gray-900 outline-0 `}
                  value={departure_date} // Use the updated state
                  onChange={(e) => {
                    handleDepartDate(e.target.value);
                    // Update RETURN min date if pickupDate changes
                    if (e.target.value > return_date && !thisoneWay) {
                      setReturnDate(e.target.value); // Reset drop date to pickup date if it's earlier
                    }
                  }} // Update date
                  min={new Date().toISOString().split("T")[0]}
                />
                {/* Error Message */}
                {errors.departure_date && (
                  <span className="text-xs text-red-500">
                    {errors.departure_date}
                  </span>
                )}
              </div>

              <div
                className={`${
                  thisoneWay ? " text-gray-700" : ""
                } rounded-xl border-b border-b-gray-500 bg-yellow-500 px-3 py-4 sm:border-b-0`}
              >
                <div className="flex items-center gap-2">
                  <FaCalendarAlt />
                  <label
                    className={`text-md ${thisoneWay ? "" : "text-white"}`}
                  >
                    RETURN
                  </label>
                </div>
                <input
                  type="date"
                  className={`z-50 mt-2 w-full cursor-pointer rounded-sm bg-transparent  p-2 outline-0 ${
                    thisoneWay ? " " : "bg-white text-gray-900"
                  }`}
                  value={return_date} // Use the updated state
                  onChange={(e) => {
                    handleReturnDate(e.target.value);
                  }} // Update date
                  disabled={thisoneWay} // Disable if one-way
                  min={departure_date || new Date().toISOString().split("T")[0]}
                />
                {/* Error Message */}
                {errors.return_date && (
                  <span className="text-xs text-red-500">
                    {errors.return_date}
                  </span>
                )}
              </div>

              <div className=" rounded-xl bg-yellow-500  px-3 py-4">
                <div className="flex items-center gap-2 text-white">
                  <FaCalendarAlt />
                  <label className="text-md text-white">TRAVELLERS</label>
                </div>
                <div className="dropdown dropdown-top w-full">
                  <div tabIndex={0} role="button" className="cursor-pointer">
                    <h3 className="text-lg">{totalTravellers} Travellers</h3>
                    <p className="text-sm">{formData.class_of_travel}</p>
                  </div>
                  <div
                    tabIndex={0}
                    className="menu dropdown-content z-[1] w-52 rounded-box bg-white p-2 shadow"
                  >
                    {/* Adults Section */}
                    <div className="cursor-pointer rounded-md border-b p-2 text-gray-900 hover:bg-gray-100">
                      <div className="flex items-center justify-between">
                        <h4 className="">Adults</h4>
                        <h4>{formData.adult}</h4>
                        <div className="flex items-center rounded-md border">
                          <div
                            className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => {
                              if (formData.adult > 1) {
                                setFormData((prev) => ({
                                  ...prev,
                                  adult: prev.adult - 1,
                                }));
                              }
                            }}
                          >
                            -
                          </div>
                          <div
                            className="cursor-pointer border border-s-0 p-1 px-3 hover:bg-green-500 hover:text-white"
                            onClick={() =>
                              setFormData((prev) => ({
                                ...prev,
                                adult: prev.adult + 1,
                              }))
                            }
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Children Section */}
                    <div className="cursor-pointer rounded-md border-b p-2 text-gray-900 hover:bg-gray-100">
                      <div className="flex items-center justify-between">
                        <div className="">
                          <h4>Children</h4>
                          <h6 className="">2-12 Years</h6>
                        </div>
                        <h4>{formData.child}</h4>
                        <div className="flex items-center rounded-md border">
                          <div
                            className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => {
                              if (formData.child > 0) {
                                setFormData((prev) => ({
                                  ...prev,
                                  child: prev.child - 1,
                                }));
                              }
                            }}
                          >
                            -
                          </div>
                          <div
                            className="cursor-pointer border border-s-0 p-1 px-3 hover:bg-green-500 hover:text-white"
                            onClick={() =>
                              setFormData((prev) => ({
                                ...prev,
                                child: prev.child + 1,
                              }))
                            }
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Infants Section */}
                    <div className="cursor-pointer rounded-md border-b p-2 text-gray-900 hover:bg-gray-100">
                      <div className="flex items-center justify-between">
                        <div>
                          <h4>Infants</h4>
                          <h6 className="">0-23 Months</h6>
                        </div>
                        <h4>{formData.infant}</h4>
                        <div className="flex items-center rounded-md border">
                          <div
                            className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => {
                              if (formData.infant > 0) {
                                setFormData((prev) => ({
                                  ...prev,
                                  infant: prev.infant - 1,
                                }));
                              }
                            }}
                          >
                            -
                          </div>
                          <div
                            className="cursor-pointer border border-s-0 p-1 px-3 hover:bg-green-500 hover:text-white"
                            onClick={() =>
                              setFormData((prev) => ({
                                ...prev,
                                infant: prev.infant + 1,
                              }))
                            }
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Flight Type Selection */}
                    <div className="text-gray-800">
                      <div className="flex cursor-pointer items-center  gap-2 rounded-md px-1 py-1 hover:bg-gray-100">
                        <input
                          type="radio"
                          id="economy"
                          name="class_of_travel"
                          value="economy"
                          checked={formData.class_of_travel === "economy"}
                          onChange={(e) =>
                            handleChange("class_of_travel", e.target.value)
                          }
                        />
                        <label htmlFor="economy" className="cursor-pointer">
                          Economy
                        </label>
                      </div>
                      <div className="flex cursor-pointer items-center gap-2 rounded-md px-1 py-1 hover:bg-gray-100">
                        <input
                          type="radio"
                          id="premiumEconomy"
                          name="class_of_travel"
                          value="premium_economy"
                          checked={
                            formData.class_of_travel === "premium_economy"
                          }
                          onChange={(e) =>
                            handleChange("class_of_travel", e.target.value)
                          }
                        />
                        <label
                          htmlFor="premiumEconomy"
                          className="cursor-pointer"
                        >
                          Premium Economy
                        </label>
                      </div>
                      <div className="flex cursor-pointer items-center gap-2 rounded-md px-1 py-1 hover:bg-gray-100">
                        <input
                          type="radio"
                          id="business"
                          name="class_of_travel"
                          value="business"
                          checked={formData.class_of_travel === "business"}
                          onChange={(e) =>
                            handleChange("class_of_travel", e.target.value)
                          }
                        />
                        <label htmlFor="business" className="cursor-pointer">
                          Business
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SubmitButton
              editing={editing}
              disabled={disabled}
              add="Submit"
              extraClass="btn flex justify-center w-32 my-4 border-0 m-auto hover:border bg-yellow-500 hover:text-yellow-500 hover:bg-white hover:border-yellow-500"
            />
          </div>
        </div>
      </form>
    </section>
  );
}
