import React from "react";

function InputField(props) {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    variant,
    state,
    disabled,
    isTextArea,
    onChange,
    value,
    register,
    name,
    errorMessage, // New prop for error message
    icon,
    rules, // New prop for validation rules
  } = props;

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`my-2 block text-sm font-medium text-gray-900 dark:text-[#bb8e25] ${
          variant === "auth" ? "ml-1.5 font-medium" : "font-bold"
        }`}
      >
        {label}
      </label>
      {isTextArea ? (
        <textarea
          {...(register && register(name, rules))} // Use rules for validation
          name={name}
          disabled={disabled}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          className={`h-12 w-full rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500 dark:border-white dark:bg-transparent dark:text-gray-200 ${
            disabled
              ? "!border-none !bg-gray-100 text-red-800 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
              : state === "error"
              ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
              : state === "success"
              ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
              : "border-gray-200 dark:!border-white/10 dark:text-white"
          }`}
        />
      ) : (
        <div className={`${icon ? "relative" : ""}`}>
          <div
            className={`${
              type === "tel" || type === "number"
                ? "rounded-md border border-gray-300 bg-gray-50 dark:!border-white/10 dark:bg-transparent"
                : ""
            }`}
          >
            <input
              {...(register && register(name, rules))} // Use rules for validation
              name={name}
              disabled={disabled}
              type={type}
              id={id}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              className={`w-full rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 focus:outline-none dark:bg-transparent dark:text-gray-200 ${
                disabled
                  ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                  : state === "error"
                  ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                  : state === "success"
                  ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                  : "border-gray-200 dark:!border-white/10 dark:text-white"
              }`}
            />
          </div>
          {icon && (
            <span className="absolute right-4 top-3 cursor-pointer">
              {icon}
            </span>
          )}
        </div>
      )}
      {/* Display error message */}
      {errorMessage && (
        <p className="mt-1 text-xs text-red-500">{errorMessage}</p>
      )}
    </div>
  );
}

export default InputField;
