import React, { useEffect, useState } from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import { MaterialReactTable } from "material-react-table";
import { Edit, Delete } from "@mui/icons-material"; // Importing Material UI icons
import { FaMailBulk, FaPlane } from "react-icons/fa"; // Importing the plane icon for departments
import InputField from "components/fields/InputField";
import userResources from "api/userResources";
import { toast } from "react-toastify";
import SubmitButton from "components/SubmitButton/SubmitButton";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";

export default function EnquiryStatus() {
  const [globalFilter, setGlobalFilter] = useState("");
  const [enquiryData, setEnquiryData] = useState([]);
  const [editingEnquirySource, setEditingEnquirySource] = useState(null);
  const [disabled, setDisabled] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    slug: "",
  });
  const [errors, setErrors] = useState({});

  const getEnquirySource = async (pageIndex, pageSize, globalFilter) => {
    setIsLoading(true); // Set loading state to true when fetching starts
    try {
      const response = await userResources.enquityStatus(
        pageIndex || 0,
        pageSize || 5,
        globalFilter || ""
      );
      setEnquiryData(response.data.data);
      setTotalRows(response.data.paginate_data.total); // Set total rows for pagination
    } catch (error) {
      console.error("Error fetching Enquiry Status data:", error);
    } finally {
      setIsLoading(false); // Set loading state to false when fetching ends
    }
  };

  const validateField = (name, value) => {
    // Field validation logic
    let errorMessage = "";
    if (name === "name" && !value) {
      errorMessage = "Enquiry Status name is required.";
    }

    return errorMessage;
  };

  const handleChange = (name, value) => {
    const fieldValue = value.target.value;

    // Update formData state
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: fieldValue,
      };

      // If the name field is being updated, generate the slug
      if (name === "name") {
        updatedData.slug = fieldValue.toLowerCase().replace(/\s+/g, "_"); // Create slug from name
      }

      return updatedData;
    });

    // Validate the field and set error message if needed
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name, fieldValue),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Disable the button during submission
    setDisabled(true);

    // Validate all fields on form submission
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const errorMessage = validateField(key, formData[key]);
      if (errorMessage) {
        newErrors[key] = errorMessage;
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fix the errors before submitting.");
      setDisabled(false); // Re-enable the button if validation fails
      return;
    }

    // Show loading toast
    const loadingToastId = toast.loading("Submitting...");

    try {
      if (editingEnquirySource) {
        await userResources.createStatus({
          id: editingEnquirySource.id,
          ...formData,
        });
        setEditingEnquirySource(null);
        getEnquirySource();
        toast.update(loadingToastId, {
          render: "Enquiry Status updated successfully.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        await userResources.createStatus({
          ...formData,
        });
        getEnquirySource();
        toast.update(loadingToastId, {
          render: "Enquiry Status added successfully.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }

      // Reset form data after successful submission
      setFormData({
        name: "",
        slug: "",
      });
    } catch (error) {
      console.error("Error submitting form data:", error);
      const errorMessage =
        error.response?.data?.message ||
        "Failed to submit form. Please try again.";

      toast.update(loadingToastId, {
        render: errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });

      if (error.response?.data?.errors) {
        const { errors } = error.response.data;
        const filteredErrors = { ...errors };
        delete filteredErrors.slug; // Remove slug error from errors
        setErrors(filteredErrors);
      }
    } finally {
      setDisabled(false); // Re-enable the button after submission
    }
  };

  const airlinesColumns = [
    { accessorKey: "name", header: "Enquiry Status" },
    { accessorKey: "slug", header: "Slug" },
    {
      accessorKey: "created_at",
      header: "Created at",
      Cell: ({ cell }) => {
        // Extract and format the date
        const date = new Date(cell.getValue()); // Create a Date object
        const formattedDate = date.toISOString().split("T")[0]; // Get only the date part (YYYY-MM-DD)
        return <span>{formattedDate}</span>; // Display the formatted date
      },
    },
    {
      accessorKey: "updated_at",
      header: "Updated at",
      Cell: ({ cell }) => {
        // Extract and format the date
        const date = new Date(cell.getValue()); // Create a Date object
        const formattedDate = date.toISOString().split("T")[0]; // Get only the date part (YYYY-MM-DD)
        return <span>{formattedDate}</span>; // Display the formatted date
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <Edit
            className="cursor-pointer text-blue-500"
            onClick={() => handleEdit(row.original)}
            aria-label={`Edit ${row.original.name}`}
          />
          <ConfirmModal
            onConfirm={handleDelete}
            title="Delete Enquiry Status"
            message="Are you sure you want to delete this enquity status?"
            employeeId={row.original.id}
          />
        </div>
      ),
    },
  ];

  const handleEdit = (enquiry_status) => {
    setFormData({
      name: enquiry_status.name,
      slug: enquiry_status.slug,
    });
    setEditingEnquirySource(enquiry_status);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCancel = () => {
    setEditingEnquirySource(null);
    setFormData({ name: "", slug: "" });
    setErrors({});
  };

  const handleDelete = async (id) => {
    // Show loading toast
    const loadingToastId = toast.loading("Deleting Enquiry Status...");

    try {
      await userResources.enquiryStatusDelete(id); // Attempt to delete the Enquiry Status

      // Update toast to show success
      toast.update(loadingToastId, {
        render: "Enquiry Status deleted successfully.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });

      // Refresh the Enquiry Status list
      getEnquirySource(pagination.pageIndex, pagination.pageSize, globalFilter);
    } catch (error) {
      console.error("Error deleting Enquiry Status:", error);

      // Update toast to show error
      const errorMessage =
        error.response?.data?.message ||
        "Failed to delete Enquiry Status. Please try again.";
      toast.update(loadingToastId, {
        render: errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      // Dismiss the loading toast if not already dismissed
      if (toast.isActive(loadingToastId)) {
        toast.dismiss(loadingToastId);
      }
    }
  };

  const handleGlobalFilterChange = (value) => {
    setGlobalFilter(value); // Update the global filter state with the new value
  };

  // Calculate total pages
  const totalPages = Math.ceil(enquiryData.length / pagination.pageSize);

  // Navigate to first page
  const goToFirstPage = () => {
    setPagination({ ...pagination, pageIndex: 0 });
  };

  // Navigate to last page
  const goToLastPage = () => {
    setPagination({ ...pagination, pageIndex: totalPages - 1 });
  };

  useEffect(() => {
    getEnquirySource();
  }, []);

  useEffect(() => {
    getEnquirySource(pagination.pageIndex, pagination.pageSize, globalFilter);
  }, [pagination, globalFilter]);

  const handlePaginationChange = (updatedPagination) => {
    setPagination(updatedPagination);
  };

  return (
    <section className="mt-6">
      <ScrollToTopOnRouteChange />
      <div className="grid grid-cols-1 gap-6 md:grid-cols-1">
        {/* Add Enquiry Status Form */}
        <div className="rounded-lg bg-white p-6 shadow-md dark:bg-gray-900">
          <h3 className="mb-4 text-xl font-semibold dark:text-white">
            {editingEnquirySource
              ? "Edit Enquiry Status"
              : "Add Enquiry Status"}
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-2">
              <InputField
                label="Enquiry Status Name"
                name="name"
                type="text"
                placeholder="Enquiry Status Name"
                value={formData.name}
                onChange={(value) => handleChange("name", value)}
                state={errors.name ? "error" : ""}
                errorMessage={errors.name}
              />

              <InputField
                label="Slug"
                name="slug"
                type="text"
                placeholder="slug"
                value={formData.slug} // Use formData.slug for the value
                onChange={(value) => handleChange("slug", value)}
                state={errors.slug ? "error" : ""}
                errorMessage={errors.slug}
                disabled={true}
              />
            </div>
            <div className="mt-6 flex space-x-2">
              {editingEnquirySource && (
                <button
                  type="button"
                  onClick={handleCancel}
                  className="w-full rounded-lg border border-red-500 p-2 text-red-500 hover:bg-red-500 hover:text-white focus:outline-none"
                >
                  Cancel
                </button>
              )}

              <SubmitButton
                editing={editingEnquirySource}
                disabled={disabled}
                add="Add Enquiry Status"
                edit="Update Enquiry Status"
              />
            </div>
          </form>
        </div>

        {/* Airlines Table */}
        <div className="rounded-lg bg-white p-6 shadow-md dark:bg-gray-900">
          <h3 className="mb-4 text-xl font-semibold dark:text-white">
            Enquiry Status List
          </h3>
          <MaterialReactTable
            columns={airlinesColumns}
            data={enquiryData}
            state={{ pagination, isLoading }} // Use both states here
            // state={{ pagination, isLoading, showProgressBars: isLoading }} // Use both states here
            enablePagination
            onPaginationChange={handlePaginationChange}
            manualPagination
            rowCount={totalRows}
            pageCount={Math.ceil(totalRows / pagination.pageSize)}
            isLoading={isLoading}
            enableColumnFilters={false}
            enableSorting={false}
            enableColumnOrdering={false}
            muiTablePaginationProps={{
              showFirstButton: true,
              showLastButton: true,
            }}
            enableGlobalFilter // Keep this true to allow global filtering
            onGlobalFilterChange={handleGlobalFilterChange} // Update the state on filter change
            globalFilter={globalFilter} // Pass the state variable to the table
          />
        </div>
      </div>
    </section>
  );
}
