import React from "react";
import CreateEnquiryForm from "components/CreateEnquiryForm/CreateEnquiryForm";
import { useLocation } from "react-router-dom";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";

export default function OneWay() {
  const location = useLocation();
  return (
    <section className="">
      <ScrollToTopOnRouteChange />
      <CreateEnquiryForm
        thisoneWay={true}
        packageType={"one_way"}
        updateData={location.state}
      />
    </section>
  );
}
