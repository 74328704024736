import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

const DateFilter = ({ selectedDate, setSelectedDate, column }) => {
  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    column.setFilterValue(newDate); // Apply column filter directly
  };

  const clearDate = () => {
    setSelectedDate("");
    column.setFilterValue(null); // Clear column filter
  };

  return (
    <div className="flex items-center gap-2">
      <input
        type="date"
        value={selectedDate}
        onChange={handleDateChange}
        className="border-0 border-b-2 bg-transparent pb-2 outline-none hover:border-gray-800"
      />
      {selectedDate && (
        <button
          onClick={clearDate}
          className="hover:text-black p-1 text-gray-500"
        >
          <AiOutlineClose size={16} />
        </button>
      )}
    </div>
  );
};

export default DateFilter;
