import React, { useState } from "react";
import Select from "react-tailwindcss-select";
import { FaCalendarAlt, FaMapMarkerAlt } from "react-icons/fa";
import options from "./City";
import InputField from "components/fields/InputField";
import SubmitButton from "components/SubmitButton/SubmitButton";
import userResources from "api/userResources";
import { toast } from "react-toastify";
import getSelectClassNames from "components/SelectFieldClasses/SelectFieldClasses";
import { useEffect } from "react";

export default function Hotel() {
  const [edit, setEdit] = useState(null);
  const [enquirySourceData, setEenquirySourceData] = useState([]);
  const [disabled, setDisabled] = useState(null);
  const [destination, setDestination] = useState();
  const [room, setRoom] = useState(1);
  const [guest, setGuest] = useState(2);
  const [checkInDate, setCheckInDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1); // Change the number to set a different future date

  // Format to YYYY-MM-DD
  const formattedCheckOutDate = tomorrow.toISOString().split("T")[0];

  // Use useState to set the initial state
  const [checkOutDate, setCheckOutDate] = useState(formattedCheckOutDate);

  const [formData, setFormData] = useState({
    title: "",
    full_name: "",
    phone_number: "",
    email: "",
    enquiry_source_id: "",
    special_requests: "",
    destination: destination?.value,
    check_in_date: checkInDate,
    check_out_date: checkOutDate,
    room: parseInt(room),
    guest: parseInt(guest),
  });

  const [errors, setErrors] = useState({});

  const handleChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
    // validateForm();
  };

  let formErrors = {};
  const validateForm = () => {
    // let formErrors = {}; // Reset errors for each validation

    // Validate destination
    if (!destination) formErrors.destination = "Destination is required.";

    // Validate title
    if (!formData.title) formErrors.title = "Title is required.";

    // Validate full name
    if (!formData.full_name) formErrors.full_name = "Full name is required.";

    // Validate contact number
    if (!formData.phone_number || !/^\d+$/.test(formData.phone_number)) {
      formErrors.phone_number =
        "Contact number is required and should contain only digits.";
    }
    if (!formData.enquiry_source_id) {
      formErrors.enquiry_source_id = "Enquiry source is required";
    }

    // Validate email
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Valid email is required.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0; // Returns true if there are no errors
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const data = { ...formData, destination: destination?.value };
    console.log(data, "formData");

    if (validateForm()) {
      try {
        const response = await userResources.addHotel(data);
        console.log(response);
        toast.success("Hotel added successfully!"); // Show success toast
        if (response.status) {
          setFormData({
            title: "",
            full_name: "",
            phone_number: "",
            email: "",
            enquiry_source_id: "",
            special_requests: "",
          });
          setDestination("");
          setCheckInDate(formattedCheckOutDate);
          setCheckOutDate(new Date().toISOString().split("T")[0]);
          setGuest(2);
          setRoom(1);
          setErrors({});
        }
        // Optionally, you can reset the form or redirect the user here
      } catch (error) {
        console.error("Error adding hotel:", error);
        if (error.response) {
          const errorMessage =
            error.response.data.message ||
            "An error occurred while adding the hotel.";
          toast.error(errorMessage); // Show error toast with server message
        } else {
          toast.error("Network error or unexpected issue."); // Show generic error toast
        }
      }
    }
  };

  const getEnquirySourceList = async () => {
    try {
      const response = await userResources.getSources();
      const data = response.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setEenquirySourceData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEnquirySourceList();
  }, []);

  return (
    <section className="text-gray-800">
      <div className="rounded-lg bg-white pb-5 text-white dark:bg-transparent">
        <div>
          <h2 className="pb-4 text-center text-xl text-gray-900 dark:text-white">
            Book Domestic and International Hotels
          </h2>
          <form onSubmit={onSubmit} className="">
            <div>
              <div className="grid grid-cols-1 gap-5 rounded-md border bg-gray-900 py-5 sm:grid-cols-2">
                {/* Destination Field */}
                <div className="border-b-2 border-r border-gray-700 px-3 sm:border-b-0">
                  <div className="flex items-center gap-2">
                    <FaMapMarkerAlt />
                    <label className="text-md  text-yellow-500">
                      ENTER YOUR DESTINATION OR PROPERTY
                    </label>
                  </div>
                  <Select
                    value={destination}
                    onChange={(value) => {
                      setDestination(value);
                      setErrors((prev) => ({ ...prev, destination: "" })); // Clear error message
                    }}
                    options={options}
                    isSearchable={true}
                    placeholder="Enter destination"
                    classNames={{
                      menuButton: ({ isDisabled }) =>
                        `flex w-full cursor-pointer mt-2 relative text-gray-900 border-0 text-lg overflow-hidden  transition-all outline-none rounded-sm duration-300 focus:outline-none cursor-pointer ${
                          isDisabled
                            ? "bg-gray-200"
                            : "bg-white  hover:border-gray-400 cursor-pointer"
                        }`,
                      menu: "z-10 w-full bg-white absolute shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-900",
                      listItem: ({ isSelected }) =>
                        `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                          isSelected
                            ? `text-white bg-gray-900`
                            : `text-gray-900 hover:bg-blue-100 hover:text-blue-500`
                        }`,
                    }}
                  />
                  {errors.destination && (
                    <p className="text-red-500">{errors.destination}</p>
                  )}
                </div>

                {/* Check-in Date Field */}
                <div className="border-r px-3">
                  <div className="flex items-center gap-2">
                    <FaCalendarAlt />
                    <label className="text-md text-yellow-500">
                      CHECK-IN DATE
                    </label>
                  </div>
                  <input
                    type="date"
                    value={checkInDate}
                    onChange={(e) => {
                      setCheckInDate(e.target.value);
                      setErrors((prev) => ({ ...prev, checkInDate: "" })); // Clear error message
                      if (e.target.value > checkOutDate) {
                        setCheckOutDate(e.target.value); // Reset drop date to pickup date if it's earlier
                      }
                    }}
                    className="mt-2 w-full rounded-sm bg-white p-2 text-gray-900"
                    min={new Date().toISOString().split("T")[0]} // Disable past dates
                  />
                  {errors.checkInDate && (
                    <p className="text-red-500">{errors.checkInDate}</p>
                  )}
                </div>

                {/* Check-out Date Field */}
                <div className="border-r px-3">
                  <div className="flex items-center gap-2">
                    <FaCalendarAlt />
                    <label className="text-md text-yellow-500">
                      CHECK-OUT DATE
                    </label>
                  </div>
                  <input
                    type="date"
                    value={checkOutDate}
                    onChange={(e) => {
                      setCheckOutDate(e.target.value);
                      setErrors((prev) => ({ ...prev, checkOutDate: "" })); // Clear error message
                    }}
                    className="mt-2 w-full rounded-sm bg-white p-2 text-gray-900"
                    min={checkInDate || new Date().toISOString().split("T")[0]} // Disable dates before Check-in Date
                  />
                  {errors.checkOutDate && (
                    <p className="text-red-500">{errors.checkOutDate}</p>
                  )}
                </div>

                {/* Rooms & Guests Field */}
                <div className="px-3">
                  <div className="flex items-center gap-2">
                    <FaCalendarAlt />
                    <label className="text-md text-yellow-500">
                      ROOMS & GUESTS
                    </label>
                  </div>
                  <div className="dropdown w-full">
                    <div tabIndex={0} role="button" className="cursor-pointer">
                      <h3 className="text-lg">
                        {room} Room{room > 1 ? "s" : ""}, {guest} Guest
                        {guest > 1 ? "s" : ""}
                      </h3>
                    </div>
                    <div
                      tabIndex={0}
                      className="dropdown-content z-[1] mt-2 w-52 bg-white p-2 text-gray-900 shadow"
                    >
                      <div className="flex items-center justify-between p-2 hover:bg-gray-100">
                        <h4>Rooms</h4>
                        <div className="flex items-center border">
                          <div
                            className="p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => {
                              if (room > 1) setRoom(room - 1);
                            }}
                          >
                            -
                          </div>
                          <div className="px-3">{room}</div>
                          <div
                            className="p-1 px-3 hover:bg-green-500 hover:text-white"
                            onClick={() => setRoom(room + 1)}
                          >
                            +
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-between p-2 hover:bg-gray-100">
                        <h4>Guests</h4>
                        <div className="flex items-center border">
                          <div
                            className="p-1 px-3 hover:bg-red-500 hover:text-white"
                            onClick={() => {
                              if (guest > 1) setGuest(guest - 1);
                            }}
                          >
                            -
                          </div>
                          <div className="px-3">{guest}</div>
                          <div
                            className="p-1 px-3 hover:bg-green-500 hover:text-white"
                            onClick={() => setGuest(guest + 1)}
                          >
                            +
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Form Fields */}
              </div>
              <div className="mt-4 grid grid-cols-1 items-end gap-4 sm:grid-cols-2">
                <InputField
                  label="Title"
                  name="title"
                  placeholder="Enter title"
                  value={formData.title}
                  onChange={(e) => handleChange("title", e.target.value)}
                  state={errors.title ? "error" : ""}
                  errorMessage={errors.title}
                />
                {/* Full Name Field */}
                <InputField
                  label="Full Name"
                  name="full_name"
                  type="text"
                  placeholder="Enter your full name"
                  value={formData.full_name}
                  onChange={(e) => handleChange("full_name", e.target.value)}
                  state={errors.full_name ? "error" : ""}
                  errorMessage={errors.full_name}
                />

                {/* Contact Number Field */}
                <InputField
                  label="Contact Number"
                  name="phone_number"
                  type="tel"
                  placeholder="Enter contact number"
                  value={formData.phone_number}
                  onChange={(e) => handleChange("phone_number", e.target.value)}
                  state={errors.phone_number ? "error" : ""}
                  errorMessage={errors.phone_number}
                />

                {/* Email Field */}
                <InputField
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={(e) => handleChange("email", e.target.value)}
                  state={errors.email ? "error" : ""}
                  errorMessage={errors.email}
                />

                <div>
                  <label className="my-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Enquiry Source
                  </label>
                  <Select
                    value={enquirySourceData.find(
                      (source) => source.value === formData.enquiry_source_id
                    )} // Controlled value
                    onChange={(selectedOption) => {
                      handleChange(
                        "enquiry_source_id",
                        selectedOption?.value || ""
                      ); // Update handler
                    }}
                    options={enquirySourceData} // Format options
                    isSearchable={true} // Enable search functionality
                    placeholder="Select..."
                    classNames={getSelectClassNames({
                      isDisabled: false,
                      selectProps: formData,
                      isSelected: false,
                      value: formData.enquiry_source_id,
                    })} // Pass parameters here}
                    formatOptionLabel={(data) => (
                      <li
                        className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                          !data.isSelected
                            ? `bg-gray-200 text-gray-900`
                            : `bg-blue-800 text-gray-200`
                        }`}
                      >
                        {data.label}
                      </li>
                    )}
                  />
                  {errors.enquiry_source_id && (
                    <span className="text-xs text-red-500">
                      {errors.enquiry_source_id}
                    </span>
                  )}
                </div>

                {/* Special Requests Field */}
                <InputField
                  label="Special Requests"
                  name="special_requests"
                  type="text"
                  placeholder="Any special requests"
                  value={formData.special_requests}
                  onChange={(e) =>
                    handleChange("special_requests", e.target.value)
                  }
                />
                <SubmitButton
                  editing={edit}
                  disabled={disabled}
                  add="SUBMIT"
                  edit="Update"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
