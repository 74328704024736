import userResources from "api/userResources";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../redux/userSlice";
import { avtar } from "utils/images";

export default function UserInfoModal({ user, onClose }) {
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentName, setDepartmentName] = useState("");
  const userInfo = useSelector(selectUserInfo);
  console.log(userInfo, "userinfo2");
  const getDepartment = async () => {
    try {
      const response = await userResources.departments(); // Replace with your API endpoint
      setDepartmentData(response.data.data);
    } catch (error) {
      console.error("Error fetching department data:", error);
    }
  };

  useEffect(() => {
    getDepartment();
  }, []);

  useEffect(() => {
    if (user && departmentData.length > 0) {
      const department = departmentData.find(
        (dept) => dept.id === user.department_id
      );
      setDepartmentName(department ? department.name : null);
    }
  }, [user, departmentData]);

  return (
    <div
      className="modal-box fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform overflow-hidden rounded-lg bg-white p-6 shadow-lg"
      style={{ zIndex: 1000 }}
    >
      <button
        className="absolute right-4 top-4 text-red-600 hover:text-red-800"
        onClick={onClose}
      >
        ✕
      </button>
      <div className="flex flex-col items-center">
        <img
          src={user?.full_path}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = avtar; // Default avatar
          }}
          alt={user?.name}
          className="mb-4 h-32 w-32 rounded-full border-4 border-blue-500 shadow-md"
        />
        <h3 className="text-2xl font-semibold text-gray-800">{user?.name}</h3>
        <div className="mt-2 space-y-1 text-center">
          <p className="text-gray-600">
            Email: <span className="text-gray-800">{user?.email}</span>
          </p>
          {user?.phone_no && (
            <p className="text-gray-600">
              Phone: <span className="text-gray-800">{user?.phone_no}</span>
            </p>
          )}
          {userInfo?.data?.id === user?.id ||
          userInfo?.data?.role_id ===
            Number(process.env.REACT_APP_ROLE_SUPERADMINcar) ||
          userInfo?.data?.role_id ===
            Number(process.env.REACT_APP_ROLE_ADMIN) ? (
            <div>
              {user?.age && (
                <p className="text-gray-600">
                  Age: <span className="text-gray-800">{user?.age}</span>
                </p>
              )}
              {user?.salary && (
                <p className="text-gray-600">
                  Salary: <span className="text-gray-800">${user?.salary}</span>
                </p>
              )}
              {user?.status && (
                <p className="text-gray-600">
                  Status: <span className="text-gray-800">{user?.status}</span>
                </p>
              )}
              {departmentName && (
                <p className="text-gray-600">
                  Department:{" "}
                  <span className="text-gray-800">{departmentName}</span>
                </p>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
