import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md"; // Import an icon for the right arrow

const RoutesDropdown = ({ options, name }) => {
  const navigate = useNavigate();
  const params = useParams();
  const currentRoute = params["*"]; // This captures the current route path

  // State to manage dropdown visibility
  const [isOpen, setIsOpen] = useState(false);

  // Improved isActive function to handle string and array cases
  const isActive = (value) => {
    if (Array.isArray(value)) {
      return value.includes(currentRoute); // Check if currentRoute is in the array
    }
    return currentRoute === value; // Direct match for strings
  };

  return (
    <div className="">
      <div
        tabIndex={0}
        role="button"
        onClick={() => setIsOpen((prev) => !prev)} // Toggle dropdown visibility
        className={`flex w-full cursor-pointer items-center justify-between font-semibold text-gray-500 hover:text-gray-900 dark:hover:text-white ${
          options.some((option) => isActive(option.value))
            ? "text-gray-900 dark:text-white"
            : ""
        }`}
      >
        <span>{name}</span>
        <MdKeyboardArrowRight
          className={`size-5 transition-transform duration-200 ${
            isOpen ? "rotate-90" : ""
          }`} // Rotate the arrow when open
        />
      </div>
      {isOpen && ( // Conditionally render the dropdown content
        <ul className=" text-gray-900 dark:text-gray-300">
          {options.map((option) => (
            <li
              key={option.value}
              onClick={() => navigate(option.route)}
              className="my-2"
            >
              <a
                className={`${
                  isActive(option.value) // Use the isActive function for styling
                    ? "font-semibold"
                    : ""
                } hover:font-semibold `}
              >
                {option.label}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default RoutesDropdown;
