import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";

// Auth Imports
// import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
  MdBusiness,
} from "react-icons/md";
import UserList from "views/admin/CRM/UserList";
import Employee from "views/admin/CRM/Employee";
import Adduser from "views/auth/AddUser";
import CreareEnquiry from "views/admin/CRM/CreareEnquiry";
import EnquiryList from "views/admin/CRM/EnquiryList";
import Airlines from "views/admin/CRM/Airlines";
import OneWay from "components/FlightWayRoute/OneWay";
import RoundTrip from "components/FlightWayRoute/RoundTrip";
import MultiCity from "components/FlightWayRoute/MultiCity";
import FlightWayRoute from "components/FlightWayRoute/FlightWayRoute";
import Departments from "views/admin/CRM/Departments";
import EnquirySource from "views/admin/CRM/EnquirySource";
import { FaEnvelope } from "react-icons/fa";
import EnquiryStatus from "views/admin/CRM/EnquiryStatus";
import CarEnquiry from "views/admin/CRM/CarEnquiry/CarEnquiry";
import Hotel from "views/admin/CRM/Hotel/Hotel";

const routes = [
  {
    name: "Main Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <MainDashboard />,
    show: true,
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   icon: (
  //     <MdOutlineShoppingCart className="h-6 w-6 text-gray-900 dark:text-white" />
  //   ),
  //   component: <NFTMarketplace />,
  //   secondary: true,
  //   show: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6 text-gray-900 dark:text-white" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  //   show: true,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6 text-gray-900 dark:text-white" />,
  //   component: <Profile />,
  //   show: true,
  // },
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "sign-in",
  //   icon: <MdLock className="text-gray-900 dark:text-white h-6 w-6" />,
  //   component: <SignIn />,
  // },
  {
    name: "Add User",
    layout: "/auth",
    path: "add-user",
    icon: <MdLock className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <Adduser />,
    show: false,
  },
  {
    name: "User List",
    layout: "/admin",
    path: "user-list",
    icon: <MdLock className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <UserList />,
    show: false,
  },
  {
    name: "Employee List",
    layout: "/admin",
    path: "employee-list",
    icon: <MdLock className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <Employee />,
    show: false,
  },
  {
    name: "Create Enquiry",
    layout: "/admin",
    path: "create-enquiry",
    icon: <MdLock className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <CreareEnquiry />,
    show: false,
    children: [
      {
        name: "Flight",
        path: "flight",
        component: <FlightWayRoute />,
        show: true,
        InnerChildren: [
          {
            name: "OneWay",
            path: "oneWay",
            component: <OneWay />,
            show: true,
          },
          {
            name: "RoundTrip",
            path: "round-trip",
            component: <RoundTrip />,
            show: true,
          },
          {
            name: "MultiCity",
            path: "multi-city",
            component: <MultiCity />,
            show: true,
          },
        ],
      },
      {
        name: "Hotel",
        path: "Hotel",
        component: <Hotel />,
        show: true,
      },
      {
        name: "Cars",
        path: "cars",
        component: <CarEnquiry />,
        show: true,
      },
    ],
  },
  {
    name: "Enquiry List",
    layout: "/admin",
    path: "enquiry-list",
    icon: <MdLock className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <EnquiryList />,
    show: false,
  },
  {
    name: "Enquiry Source", // Updated name for the component
    layout: "/admin",
    path: "enquiry-source", // Updated path for the Enquiry Source component
    icon: <FaEnvelope className="h-6 w-6 text-gray-900 dark:text-white" />, // Icon remains the same or can be customized
    component: <EnquirySource />, // Updated to use EnquirySource component
    show: false, // Set to true to make it visible in the navigation
  },
  {
    name: "Enquiry Status", // Updated name for the component
    layout: "/admin",
    path: "enquiry-status", // Updated path for the Enquiry Source component
    icon: <FaEnvelope className="h-6 w-6 text-gray-900 dark:text-white" />, // Icon remains the same or can be customized
    component: <EnquiryStatus />, // Updated to use EnquirySource component
    show: false, // Set to true to make it visible in the navigation
  },
  {
    name: "Air Lines",
    layout: "/admin",
    path: "air-lines",
    icon: <MdLock className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <Airlines />,
    show: false,
  },
  {
    name: "Department",
    layout: "/admin",
    path: "department",
    icon: <MdBusiness className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <Departments />,
    show: false,
  },
];
export default routes;
