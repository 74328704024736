import React from "react";

export default function Background({ image, darkness }) {
  return (
    <div
      className="h-[94vh] overflow-hidden"
      style={{
        position: "relative",
        backgroundSize: "cover",
      }}
    >
      <img src={image} alt="" />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: darkness ? darkness : "rgba(0, 0, 0, 0.4)",
        }}
      ></div>
    </div>
  );
}
