import Login from "Auth/AgencyLogin/Login";
import React, { useState } from "react";
import { MdPersonAdd } from "react-icons/md";
import { RiLoginBoxLine } from "react-icons/ri";
// import Login from "auth/Login";
// import SignUp from "auth/SignUp";

export default function LoginRegisterButton({ dropDown }) {
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);

  return (
    <div className={`dropdown ${dropDown} mr-2 w-full`}>
      <div
        tabIndex={0}
        role="button"
        className="btn m-1 mr-1 w-full border-none bg-[#bb8e25] py-0 uppercase text-white hover:bg-white hover:text-[#bb8e25]"
      >
        login/register
      </div>
      {!openLogin && (
        <ul
          tabIndex={0}
          className="menu dropdown-content z-[1] w-full rounded-box bg-white p-2 shadow"
        >
          <div
            className="flex cursor-pointer items-center rounded-2xl p-3 hover:bg-gray-200"
            onClick={() => setOpenLogin(true)}
          >
            <RiLoginBoxLine className="text-lg text-gray-800" />
            <p className="ml-3 w-full text-gray-900">Login</p>
          </div>

          <div
            className="flex cursor-pointer items-center rounded-2xl p-3 hover:bg-gray-200"
            onClick={() => setOpenSignUp(true)}
          >
            <MdPersonAdd className="text-lg text-gray-800" />
            <p className="ml-3 w-full text-gray-900">Sign Up</p>
          </div>
          {/* {openSignUp && <SignUp open={openSignUp} setOpen={setOpenSignUp} />} */}
        </ul>
      )}
      {openLogin && <Login openLogin={openLogin} setOpenLogin={setOpenLogin} />}
    </div>
  );
}
