import React from "react";

export default function Logo({ logo1, logo2, logo3 }) {
  return (
    <section>
      <div className="flex flex-col items-center">
        <img src={logo1} alt="" className="w-16" />
        <img src={logo2} alt="" className="w-44" />
        <img src={logo3} alt="" className="w-60" />
      </div>
    </section>
  );
}
