import React from "react";
import CreateEnquiryForm from "components/CreateEnquiryForm/CreateEnquiryForm";
import { useLocation } from "react-router-dom";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";

export default function RoundTrip() {
  const location = useLocation();
  console.log(location, "round");
  return (
    <section>
      <ScrollToTopOnRouteChange />
      <CreateEnquiryForm
        disable={false}
        packageType={"return_way"}
        updateData={location.state}
      />
    </section>
  );
}
