import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes.js";
import {
  MdAirplanemodeActive,
  MdAssignment,
  MdAssignmentInd,
  MdBusiness,
  MdLock,
  MdPeopleOutline,
  MdQuestionAnswer,
} from "react-icons/md";
import { logout } from "../../redux/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import RoutesDropdown from "components/RoutesDropdown/RoutesDropdown";
import { useState } from "react";
import { FaEnvelope, FaPlane, FaUsers } from "react-icons/fa";
import { RiCustomerService2Line } from "react-icons/ri";
import katariatravels from "utils/images";
import { toast } from "react-toastify";
import userResources from "api/userResources";
import Logout from "components/Logout/Logout";

const Sidebar = ({ open, onClose }) => {
  const [dropDown, setDropdown] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const currentRoute = params["*"]; // This will capture the current route path
  console.log(currentRoute, "currentRoute");

  // Filter routes to only include those with a name key
  const filteredRoutes = routes.filter((route) => route.show);

  // Function to check if the current route matches any dropdown option value or config value
  const isActiveRoute = (optionValue) => {
    // Check if the optionValue is an array and if currentRoute matches any value in it
    if (Array.isArray(optionValue)) {
      return optionValue.includes(currentRoute);
    }
    // Otherwise, check for a direct match
    return currentRoute === optionValue;
  };

  // Updated function to check if CRM is active
  const isCRMActive = () => {
    return dropdownConfigs.some(
      (config) =>
        // Check against options
        config.options?.some((option) => isActiveRoute(option.value)) ||
        // Check against the config value
        isActiveRoute(config.value)
    );
  };

  // Dropdown configuration array
  const dropdownConfigs = [
    {
      name: "User Management",
      icon: <FaUsers className="mr-4 h-6 w-6 text-gray-900 dark:text-white" />,
      options: [
        { value: "add-user", label: "Add User", route: "/auth/add-user" },
        { value: "user-list", label: "User List", route: "/admin/user-list" },
        {
          value: "employee-list",
          label: "Employee List",
          route: "/admin/employee-list",
        },
      ],
    },
    {
      name: "Enquiry",
      icon: (
        <MdQuestionAnswer className="mr-4 h-6 w-6 text-gray-900 dark:text-white" />
      ),
      options: [
        {
          value: [
            "create-enquiry",
            "create-enquiry/flight/round-trip",
            "create-enquiry/flight/oneway",
            "create-enquiry/flight/multi-city",
          ],
          label: "Create New Enquiry",
          route: "/admin/create-enquiry/flight/round-trip",
        },
        {
          value: "enquiry-list",
          label: "Enquiry List",
          route: "/admin/enquiry-list",
        },
        {
          value: "enquiry-source",
          label: "Enquiry Source",
          route: "/admin/enquiry-source ",
        },
        {
          value: "enquiry-status",
          label: "Enquiry Status",
          route: "/admin/enquiry-status",
        },
      ],
    },
    {
      name: "Air Lines",
      icon: (
        <MdAirplanemodeActive className="mr-4 h-6 w-6 text-gray-900 dark:text-white" />
      ),
      route: "/admin/air-lines",
      value: "air-lines",
    },
    {
      name: "Department",
      icon: (
        <MdAssignmentInd className="mr-4 h-6 w-6 text-gray-900 dark:text-white" />
      ),
      route: "/admin/department",
      value: "department",
    },
  ];

  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[100px] mt-[10px] flex items-center`}>
        <div className="ml-1 mt-1 h-2.5 font-poppins text-[22px] font-bold uppercase text-navy-700 dark:text-[#dea113]">
          {/* Kataria <span className="font-medium">Travels</span> */}
          <img src={katariatravels} alt="" className="w-24" />
        </div>
      </div>
      <div className="mb-7 mt-[100px] h-px bg-gray-300 dark:bg-white/30" />

      <ul className="mb-auto pt-1">
        <Links routes={filteredRoutes} />
        {/* CRM */}
        <li className="mx-8 my-4">
          <div
            className="flex cursor-pointer items-center"
            onClick={() => setDropdown(!dropDown)}
          >
            <RiCustomerService2Line className="mr-4 h-6 w-6 text-gray-900 dark:text-white" />
            <p
              className={`font-semibold text-gray-500 hover:text-gray-900 dark:hover:text-white ${
                isCRMActive() ? "text-gray-900 dark:text-white" : ""
              }`}
            >
              CRM
            </p>
          </div>

          {/* Render dropdowns dynamically */}
          {dropDown && (
            <ul>
              {dropdownConfigs.map((config, index) => (
                <li key={index} className="my-4 flex cursor-pointer ">
                  {config.icon}
                  <li>
                    {config.route ? (
                      <div
                        onClick={() => navigate(config.route)}
                        className={`font-semibold ${
                          isActiveRoute(config.value)
                            ? "text-gray-900 dark:text-white"
                            : "text-gray-500"
                        }`}
                      >
                        {config.name}
                      </div>
                    ) : (
                      <RoutesDropdown
                        options={config.options}
                        name={config.name}
                      />
                    )}
                  </li>
                </li>
              ))}
            </ul>
          )}
        </li>
      </ul>
      <Logout />

      <div className="flex justify-center">{/* <SidebarCard /> */}</div>
    </div>
  );
};

export default Sidebar;
