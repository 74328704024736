import React from "react";
import { Delete } from "@mui/icons-material";

export default function ConfirmModal({ onConfirm, title, message, employeeId }) {
  const handleOpenModal = () => {
    const modal = document.getElementById(`modal_${employeeId}`);
    if (modal) modal.showModal();
  };

  const handleCloseModal = () => {
    const modal = document.getElementById(`modal_${employeeId}`);
    if (modal) modal.close();
  };

  return (
    <section>
      <button onClick={handleOpenModal}>
        <Delete className="cursor-pointer text-red-500" />
      </button>
      <dialog id={`modal_${employeeId}`} className="modal">
        <div className="modal-box rounded-none bg-white py-10 text-gray-900">
          <form method="dialog">
            <button
              className="btn btn-circle btn-ghost btn-sm absolute right-2 top-2 text-red-600"
              onClick={handleCloseModal}
            >
              ✕
            </button>
            <div className="flex flex-col items-center justify-center">
              <div className="rounded-full border-2 border-red-500 px-7 py-5 text-lg font-bold text-red-500">
                ✕
              </div>
              <div className="my-2 text-center sm:my-4 md:my-4">
                <h3 className="text-lg sm:text-3xl">
                  {title || "Are you sure?"}
                </h3>
                <p className="text-gray-400">
                  {message || "Do you really want to delete this record?"}
                </p>
              </div>
              <div className="flex items-center justify-center gap-5">
                <button
                  className="btn mt-2 rounded-none border-none bg-gray-300 px-11 font-extralight tracking-wider text-white hover:bg-gray-400 sm:mt-4 md:mt-6"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  className="btn mt-2 space-x-4 rounded-none border-none bg-red-500 px-11 font-extralight tracking-wider text-white hover:bg-red-600 sm:mt-4 md:mt-6"
                  onClick={(e) => {
                    e.preventDefault();
                    onConfirm(employeeId); // Pass employeeId to onConfirm
                    handleCloseModal(); // Close modal after confirm
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </form>
        </div>
      </dialog>
    </section>
  );
}
