import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

export default function FlightWayRoute() {
  const location = useLocation();

  // Define the tab configurations
  const tabs = [
    { path: "/admin/create-enquiry/flight/round-trip", label: "Round Trip" },
    { path: "/admin/create-enquiry/flight/oneway", label: "One Way" },
    { path: "/admin/create-enquiry/flight/multi-city", label: "Multi City" },
  ];

  return (
    <section>
      {location.pathname !== "/" ? (
        <div className="m-auto flex flex-wrap items-center justify-between font-semibold text-gray-800">
          <fieldset className="flex gap-2">
            {tabs.map((tab) => {
              const isActive = location.pathname === tab.path;
              return (
                <Link key={tab.path} to={tab.path}>
                  <div className="flex items-center gap-2 rounded-full border bg-white px-3 py-0.5 text-sm">
                    <div
                      className={`h-4 w-4 rounded-full border-2 border-gray-300 ${
                        isActive ? "bg-yellow-400" : ""
                      }`}
                    ></div>
                    <label
                      htmlFor={tab.label.replace(" ", "").toLowerCase()}
                      className="cursor-pointer whitespace-nowrap"
                    >
                      {tab.label}
                    </label>
                  </div>
                </Link>
              );
            })}
          </fieldset>
        </div>
      ) : null}
      <Outlet />
    </section>
  );
}
