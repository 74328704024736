import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

export default function Flight() {
  const location = useLocation();

  // Define the tab configurations
  const tabs = [
    {
      path: "round-trip",
      label: "Round Trip",
      bg: ["/", "/flights", "/flights/round-trip"],
    },
    { path: "oneway", label: "One Way", bg: ["/flights/oneway"] },
    {
      path: "multi-city",
      label: "Multi destination",
      bg: ["/flights/multi-city"],
    },
  ];
  return (
    <section className="">
      <div className="absolute right-4 top-0  m-auto flex flex-wrap items-center justify-between font-semibold text-gray-800">
        <fieldset className="flex gap-2">
          {tabs.map((tab) => {
            const isActive = tab.bg.includes(location.pathname); // Check if currentRoute is included in the bg array
            return (
              <Link key={tab.path} to={tab.path}>
                <div className="flex items-center gap-2 rounded-full border bg-white px-3 py-0.5 text-sm">
                  <div
                    className={`h-4 w-4 rounded-full border-2 border-gray-300 ${
                      isActive ? "bg-yellow-400" : ""
                    }`}
                  ></div>
                  <label
                    htmlFor={tab.label.replace(" ", "").toLowerCase()}
                    className="cursor-pointer whitespace-nowrap"
                  >
                    {tab.label}
                  </label>
                </div>
              </Link>
            );
          })}
        </fieldset>
      </div>
      <Outlet />
    </section>
  );
}
