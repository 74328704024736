import React from "react";
import { FaFacebook, FaInstagram, FaWhatsapp, FaTwitter } from "react-icons/fa";
import Logo from "../Logo/Logo";
import { logo1 } from "utils/images";
import { logo2 } from "utils/images";
import { logo3 } from "utils/images";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import LoginRegisterButton from "../LoginRegisterButton/LoginRegisterButton";

export default function Navbar() {
  const [darkmode, setDarkmode] = React.useState(false);
  return (
    <nav className="absolute top-0 z-10 w-full font-semibold text-white">
      <section className="m-auto max-w-7xl py-2">
        <div className="flex items-center justify-between">
          <div>
            <Logo logo1={logo1} logo2={logo2} logo3={logo3} />
          </div>
          <div className="flex items-center">
            <div
              className="cursor-pointer px-3 text-gray-600"
              onClick={() => {
                if (darkmode) {
                  document.body.classList.remove("dark");
                  setDarkmode(false);
                } else {
                  document.body.classList.add("dark");
                  setDarkmode(true);
                }
              }}
            >
              {darkmode ? (
                <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
              ) : (
                <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
              )}
            </div>
            <LoginRegisterButton />
          </div>
        </div>
        <div></div>
      </section>
    </nav>
  );
}
