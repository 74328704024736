import React from "react";
import RowRoutesIcon from "components/RowRoutesIcon/RowRoutesIcon";
import { FaPlane, FaUmbrellaBeach, FaCar } from "react-icons/fa"; // Import relevant icons
import { Outlet, useLocation } from "react-router-dom";

export default function HeroSearchBox() {
  const location = useLocation();
  console.log(location, "location");

  const routes = [
    {
      label: "Flight",
      url: "flights",
      icon: <FaPlane />,
      bg: [
        "/",
        "/flights",
        "/flights/round-trip",
        "/flights/oneway",
        "/flights/multi-city",
      ],
    },
    {
      label: "Hotel",
      url: "hotels",
      icon: <FaUmbrellaBeach />,
      bg: ["/hotels"],
    },
    {
      label: "Cars",
      url: "cars",
      icon: <FaCar />,
      bg: ["/cars"],
    },
  ];
  return (
    <section className="m-auto max-w-7xl rounded-xl bg-white ">
      <div className="relative top-2 ">
        <RowRoutesIcon routes={routes} extraClass={"left-3"}/>
        <Outlet />
      </div>
    </section>
  );
}
