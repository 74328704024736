import React, { useState } from "react";
import Select from "react-tailwindcss-select";
import { FaCalendarAlt, FaCarAlt, FaMapMarkerAlt } from "react-icons/fa";
import InputField from "components/fields/InputField";
import options from "./city"; // Import your city options here
import SubmitButton from "components/SubmitButton/SubmitButton";

export default function CarEnquiry() {
  const [disabled, setDisabled] = useState();
  const [edit, setEdit] = useState();
  const [pickupLocation, setPickupLocation] = useState(null);
  const [dropoffLocation, setDropoffLocation] = useState(null);
  const [pickupDate, setPickupDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [dropDate, setDropDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [carType, setCarType] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    fullName: "",
    contactNumber: "",
    email: "",
    specialRequests: "",
  });
  const [errors, setErrors] = useState({});

  const carOptions = [
    { value: "sedan", label: "Sedan" },
    { value: "suv", label: "SUV" },
    { value: "hatchback", label: "Hatchback" },
    { value: "luxury", label: "Luxury" },
  ];

  const validateField = (name, value) => {
    let error = "";

    if (name === "title") {
      error = value ? "" : "Title is required.";
    } else if (name === "fullName") {
      error = value ? "" : "Full name is required.";
    } else if (name === "contactNumber") {
      error = value ? "" : "Contact number is required.";
    } else if (name === "email") {
      error = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
        ? ""
        : "Enter a valid email address.";
    } else if (name === "pickupDate") {
      if (!value) error = "Pickup date is required.";
      else if (new Date(value) < new Date())
        error = "Pickup date cannot be in the past.";
    } else if (name === "dropDate") {
      if (!value) error = "Drop-off date is required.";
      else if (new Date(value) < new Date(pickupDate))
        error = "Drop-off date must be after the pickup date.";
    }

    return error;
  };

  const handleChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear error on change
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate fields based on your existing validateField function
    for (let field in formData) {
      const errorMessage = validateField(field, formData[field]);
      if (errorMessage) newErrors[field] = errorMessage;
    }

    // Validate required fields
    if (!pickupLocation)
      newErrors.pickupLocation = "Pickup location is required.";
    if (!dropoffLocation)
      newErrors.dropoffLocation = "Drop-off location is required.";
    if (!carType) newErrors.carType = "Car type is required.";

    // Validate pickupDate
    if (!pickupDate) {
      newErrors.pickupDate = "Pickup date is required.";
    } else if (
      new Date(pickupDate) < new Date(new Date().setHours(0, 0, 0, 0))
    ) {
      // Set today's date to the start of the day (midnight) to consider it valid
      newErrors.pickupDate = "Pickup date cannot be in the past.";
    }

    // Validate dropDate
    if (!dropDate) {
      newErrors.dropDate = "Drop-off date is required.";
    } else if (new Date(dropDate) < new Date(pickupDate)) {
      newErrors.dropDate = "Drop-off date must be after the pickup date.";
    }

    // Set errors and return validity
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const data = {
      pickupLocation,
      dropoffLocation,
      pickupDate,
      dropDate,
      carType,
      ...formData,
    };
    console.log(data);
  };

  return (
    <section className="text-gray-800">
      <div className="rounded-lg bg-white pb-5 text-white dark:bg-transparent">
        <div>
          <h2 className="pb-4 text-center text-xl text-gray-900 dark:text-white">
            Book Your Car Rental
          </h2>
          <form onSubmit={onSubmit}>
            <div className="justify-start gap-5 rounded-md border bg-gray-900 py-5">
              <div className="grid grid-cols-1 py-2 sm:grid-cols-2">
                {/* Pickup Location Field */}
                <div className="border-r border-gray-700 px-3">
                  <div className="flex items-center gap-2 py-2">
                    <FaMapMarkerAlt className="text-white" />
                    <label className="text-md text-yellow-500">
                      PICKUP LOCATION
                    </label>
                  </div>
                  <Select
                    value={pickupLocation}
                    onChange={(value) => {
                      setPickupLocation(value);
                      setErrors((prev) => ({ ...prev, pickupLocation: "" }));
                    }}
                    options={options}
                    isSearchable={true}
                    placeholder="Enter pickup location"
                  />
                  {errors.pickupLocation && (
                    <p className="text-red-500">{errors.pickupLocation}</p>
                  )}
                </div>

                {/* Drop-off Location Field */}
                <div className="border-r border-gray-700 px-3">
                  <div className="flex items-center gap-2 py-2">
                    <FaMapMarkerAlt className="text-white" />
                    <label className="text-md text-yellow-500">
                      DROP-OFF LOCATION
                    </label>
                  </div>
                  <Select
                    value={dropoffLocation}
                    onChange={(value) => {
                      setDropoffLocation(value);
                      setErrors((prev) => ({ ...prev, dropoffLocation: "" }));
                    }}
                    options={options}
                    isSearchable={true}
                    placeholder="Enter drop-off location"
                  />
                  {errors.dropoffLocation && (
                    <p className="text-red-500">{errors.dropoffLocation}</p>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2">
                {/* Pickup Date Field */}
                <div className="border-r px-3">
                  <div className="flex items-center gap-2 py-2">
                    <FaCalendarAlt />
                    <label className="text-md text-yellow-500">
                      PICKUP DATE
                    </label>
                  </div>
                  <input
                    type="date"
                    value={pickupDate}
                    onChange={(e) => {
                      const selectedDate = e.target.value;
                      setPickupDate(selectedDate);
                      setErrors((prev) => ({ ...prev, pickupDate: "" }));

                      // Update dropDate min date if pickupDate changes
                      if (selectedDate > dropDate) {
                        setDropDate(selectedDate); // Reset drop date to pickup date if it's earlier
                      }
                    }}
                    min={new Date().toISOString().split("T")[0]} // Set min to today’s date
                    className="z-50 w-full cursor-pointer rounded-sm bg-transparent bg-white p-2 text-gray-900 outline-0"
                  />
                  {errors.pickupDate && (
                    <p className="text-red-500">{errors.pickupDate}</p>
                  )}
                </div>

                {/* Drop Date Field */}
                <div className="border-r px-3 py-2 sm:py-0">
                  <div className="flex items-center gap-2 py-2">
                    <FaCalendarAlt />
                    <label className="text-md text-yellow-500">
                      DROP OFF DATE
                    </label>
                  </div>
                  <input
                    type="date"
                    value={dropDate}
                    onChange={(e) => {
                      setDropDate(e.target.value);
                      setErrors((prev) => ({ ...prev, dropDate: "" }));
                    }}
                    min={pickupDate || new Date().toISOString().split("T")[0]} // Set min to pickup date or today if not selected
                    className="z-50 w-full cursor-pointer rounded-sm bg-transparent bg-white p-2 text-gray-900 outline-0"
                  />
                  {errors.dropDate && (
                    <p className="text-red-500">{errors.dropDate}</p>
                  )}
                </div>
              </div>

              {/* Car Type Field */}
              <div className="px-3">
                <div className="flex items-center gap-2 py-2">
                  <FaCarAlt className="text-white" />
                  <label className="text-md text-yellow-500">
                    SELECT CAR TYPE
                  </label>
                </div>
                <Select
                  value={carType}
                  onChange={(value) => {
                    setCarType(value);
                    setErrors((prev) => ({ ...prev, carType: "" }));
                  }}
                  options={carOptions}
                  isSearchable={true}
                  placeholder="Select car type"
                />
                {errors.carType && (
                  <p className="text-red-500">{errors.carType}</p>
                )}
              </div>
            </div>

            {/* Additional Fields */}
            <div className="mt-4 grid w-full grid-cols-1 items-end gap-4 sm:grid-cols-2">
              {[
                "title",
                "fullName",
                "contactNumber",
                "email",
                "specialRequests",
              ].map((field) => (
                <div key={field}>
                  <InputField
                    label={field.replace(/([A-Z])/g, " $1").toUpperCase()}
                    name={field}
                    type={field === "contactNumber" ? "number" : "text"}
                    placeholder={`Enter ${field}`}
                    value={formData[field]}
                    onChange={(e) => handleChange(field, e.target.value)}
                    state={errors[field] ? "error" : ""}
                    errorMessage={errors[field]}
                  />
                  {/* {errors[field] && (
                    <p className="text-red-500">{errors[field]}</p>
                  )} */}
                </div>
              ))}
              <SubmitButton
                editing={edit}
                disabled={disabled}
                add="SUBMIT"
                edit="Update"
              />
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
