// SelectFilter.js
import React from "react";
import { AiOutlineClose } from "react-icons/ai";

const SelectFilter = ({
  options,
  selectedId,
  setSelectedId,
  column,
  placeholder,
}) => {
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value ? parseInt(e.target.value) : null;
    setSelectedId(selectedValue);

    // Find the name associated with the selected ID and set it as the filter value
    const selectedOption = options.find(
      (option) => option.id === selectedValue
    );
    column.setFilterValue(selectedOption ? selectedOption.name : null);
  };

  const clearSelection = () => {
    setSelectedId(null); // Clear the selection
    column.setFilterValue(null); // Reset the filter
  };

  return (
    <div className="mt-2 flex items-center justify-between gap-2">
      <select
        value={selectedId || ""}
        className="w-full border-0 border-b-2 bg-transparent pb-1 outline-none hover:border-gray-800"
        onChange={handleSelectChange}
      >
        <option value="">{placeholder || "All"}</option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>

      {selectedId && (
        <button
          onClick={clearSelection}
          className="hover:text-black p-1 text-gray-500"
        >
          <AiOutlineClose size={16} />
        </button>
      )}
    </div>
  );
};

export default SelectFilter;
